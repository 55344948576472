import { BaseService } from "./BaseService";
import { downloadBlob } from "../components/Global/tools.js";
import moment from "moment";

export class CandidateService extends BaseService {
  getCandidatesOverviewMobile(filter) {
    return this.request(`Candidates/GetCandidatesOverviewMobile`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(filter),
    });
  }

  getCandidateInfoMobile(candidateId) {
    return this.request(`Candidates/GetCandidateInfoMobile/${candidateId}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  getCandidateReportPdf(candidateId) {
    return this.request(`Candidates/GetCandidateReportPdf/${candidateId}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  getCandidatePhone(candidateId) {
    return this.request(`Candidates/GetCandidatePhone/${candidateId}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }
  
  getCandidatesReportFileUrl(candidatesIds) {
    return this.internalRequest(`Candidates/GetCandidatesReportFileUrl/${candidatesIds}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  shareCandidateToken(candidatesIds) {
    return this.request(`Candidates/ShareCandidateToken`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(candidatesIds),
    });
  }

  generateCandidatesReport(candidatesIds) {
    return this.internalRequest(`Candidates/GenerateCandidatesReport/${candidatesIds}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
    });
  }

  // model => { "Subject":"Assunto", "Body":"Mensagem...", "CandidatesIds":[1,2] }
  sendEmailToCandidate(model) {
    return this.request(`Candidates/SendEmailToCandidates`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(model),
    });
  }

  async getCandidateReportAsBlob(candidateId) {
    const response = await this.getCandidateReportPdf(candidateId);

    if (response.ok) {
      return response.blob();
    }

    return null;
  }

  async getCandidateReportAsFile(candidateId) {
    const blob = await this.getCandidateReportAsBlob(candidateId);

    if (blob) {
      return new File([blob], this.getReportFileName(candidateId), {
        type: "application/pdf",
      });
    }

    return null;
  }

  async downloadCandidateReport(candidateId) {
    const blob = await this.getCandidateReportAsBlob(candidateId);

    if (blob) {
      downloadBlob(blob, this.getReportFileName(candidateId));
    }
  }

  getReportFileName(candidateId) {
    return "Report" + candidateId + "_" + moment().unix() + ".pdf";
  }

  shareCandidatesReports(candidatesIds) {
    var files = [];

    for (const candidateId of candidatesIds) {
      this.getCandidateReportAsFile(candidateId).then((file) => {
        if (file) {
          files.push(file);
        }
      });
    }

    if (navigator.canShare({ files: files })) {
      navigator.share({
        files: files,
      });

      return true;
    }

    return false;
  }
}

const candidateService = new CandidateService();

export default candidateService;
