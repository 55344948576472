import React, { Component } from "react";
import { Link } from "react-router-dom";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";

//SERVICES
import AccountService from "../../../services/AccountService.js";

// COMPONENTS
import ButtonCTA from "../../Elements/Buttons/ButtonCTA.js";
import Input from "../../Elements/Forms/Input.js";

// IMAGES
import { ReactComponent as MlogLogo } from "../../Global/images/mlogLogoSignature.svg";
import { ReactComponent as PoweredBy } from "../../Global/icons/poweredBy.svg";
import accountService from "../../../services/AccountService.js";

const StyledRecoverPassword = styled(NanoFlex)`
  background: linear-gradient(0deg, #0b679f 0%, #ffffff 100%);
  height: 100vh;
  width: 100vw;

  .logoWrapper {
    height: 20vh;
    padding-top: 5vh;
    svg {
      width: auto;
      height: 100%;
    }
  }

  .loginContainer {
    height: calc(100vh - 25vh);
    position: relative;

    .bckWrapper {
      position: fixed;
      height: 100%;
      width: 100%;

      .bckObject {
        width: 100%;
        height: 100%;
        transform: skew(0deg, -6deg);
        background-color: ${(props) => props.theme.color.main.blue};
        border-radius: 55px;
        position: absolute;
        top: 20px;
      }
      .bckObject2 {
        width: 100%;
        height: 100%;
        transform: skew(0deg, -6deg) rotate(-6deg);
        background-color: ${(props) => props.theme.color.main.blue};
        opacity: 0.4;
        border-radius: 55px;
      }
    }

    /* LOGIN */
    .loginWrapper {
      padding: 60px 20px 20px 20px;
      position: relative;
      .form {
        width: 100%;
        a {
          text-decoration: none;
        }

        .errorMessagePasswords {
          margin-top: 4px;
          background-color: ${(props) => props.theme.color.main.red};
          padding: 8px;
          h6 {
            text-align: center;
            color: ${(props) => props.theme.color.greyPalette.white};
          }
        }
      }
    }
    /* END LOGIN */

    .formWrapper {
      padding: 20px 5vw;
      position: relative;
      z-index: 2;
      height: auto;
      .headerForm {
        height: auto;
        margin-bottom: 32px;
        h2 {
          b {
            color: ${(props) => props.theme.color.greyPalette.white};
          }
        }
      }
      .formContainer {
        .errorMessagePasswords {
        }
      }

      .messageWrapper {
        .iconMessageWrapper {
          margin-bottom: 24px;
          svg {
            height: 80px;
            width: auto;
            path {
              fill: ${(props) => props.theme.color.greyPalette.white};
            }
          }
        }
        .messageContainer {
          text-align: center;
          h5 {
            margin-bottom: 16px;
            color: ${(props) => props.theme.color.greyPalette.white};
            text-transform: uppercase;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      .submitContainer {
        margin-top: 24px;
      }

      .passwordForgotContainer {
        margin-top: 24px;
        p {
          text-align: center;
          cursor: pointer;
          color: ${(props) => props.theme.color.greyPalette.white};
        }
      }

      .backToLoginBtn {
        margin-top: 32px;
        h4 {
          text-transform: uppercase;
          text-align: center;
          cursor: pointer;
          color: ${(props) => props.theme.color.greyPalette.white};
        }
      }
    }

    .poweredWrapper {
      position: relative;
      z-index: 2;
      height: auto;
      padding: 20px;
      .poweredBy {
        width: 30vw;
        svg {
          width: auto;
          height: auto;
        }
      }
    }
  }

  .inputContainer {
    .title {
      color: ${(props) => props.theme.color.greyPalette.white};
    }
  }
`;

export class RecoverPassword extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      newPassword: "",
      newPasswordError: false,
      repeatNewPassword: "",
      repeatNewPasswordError: false,
      passwordsNotMatching: false,
      errorMessage: "",
    };
  }

  componentDidMount() {
    super.componentDidMount();

    if (accountService.getToken() !== null) {
      /*Remove User From LocalStorage */
      accountService.removeToken();
    }

    this.validateToken();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  validateToken = () => {
    if (this.props.render.location.search && new URLSearchParams(this.props.render.location.search).get("Token")) {
      let token = new URLSearchParams(this.props.render.location.search).get("Token");

      accountService
        .isTokenValid(token)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            this.props.render.history.push("/login");
          }
        })
        .then((data) => {
          if (!data) {
            this.props.render.history.push("/login");
          }
        });

      this.setState({ token: token });
    } else {
      this.props.render.history.push("/login");
    }
  };

  recoverPassword = (event) => {
    let canRecoverPassword = true;
    // Prevent page refresh
    event.preventDefault();

    this.setState({ errorMessage: "" });

    if (!this.state.newPassword || this.state.newPassword.trim() == "") {
      canRecoverPassword = false;
      this.setState({ newPasswordError: true });
    } else {
      this.setState({ newPasswordError: false });
    }

    if (!this.state.repeatNewPassword || this.state.repeatNewPassword.trim() == "") {
      canRecoverPassword = false;
      this.setState({ repeatNewPasswordError: true });
    } else {
      this.setState({ repeatNewPasswordError: false });
    }

    if (canRecoverPassword && this.state.newPassword !== this.state.repeatNewPassword) {
      canRecoverPassword = false;
      this.setState({ passwordsNotMatching: true, newPasswordError: true, repeatNewPasswordError: true });
    } else {
      this.setState({ passwordsNotMatching: false });
    }

    if (canRecoverPassword) {
      let status = null;
      accountService
        .changePassword(this.state.token, this.state.newPassword)
        .then((response) => {
          status = response.ok;
          return response.json();
        })
        .then((data) => {
          if (status) {
            this.props.render.history.push("/login");
          } else {
            this.setState({ errorMessage: data.Message });
          }
        });
    }
  };

  render() {
    return (
      <StyledRecoverPassword flexDirection="column" justifyContent="space-between">
        <NanoFlex className="logoWrapper">
          <MlogLogo />
        </NanoFlex>
        <NanoFlex className="loginContainer" flexDirection="column" justifyContent="space-between">
          <NanoFlex className="bckWrapper">
            <NanoFlex className="bckObject"></NanoFlex>
            <NanoFlex className="bckObject2"></NanoFlex>
          </NanoFlex>

          {/* LOGIN */}
          <NanoFlex className="loginWrapper" flexDirection="column" justifyContent="space-between">
            <form className="form" onSubmit={this.recoverPassword}>
              <NanoFlex className="formWrapper" flexDirection="column">
                <NanoFlex className="headerForm">
                  <Type.h2>
                    <b>RECUPERAR PASSWORD</b>
                  </Type.h2>
                </NanoFlex>
                <NanoFlex className="formContainer" flexDirection="column">
                  <Input title="Password" placeholder="Password" eye={true} error={this.state.newPasswordError} onChangeAction={(value) => this.setState({ newPassword: value })} />
                  <Input title="Repetir Password" placeholder="Repetir Password" eye={true} error={this.state.repeatNewPasswordError} onChangeAction={(value) => this.setState({ repeatNewPassword: value })} />
                  {this.state.errorMessage && (
                    <NanoFlex className="errorMessagePasswords">
                      <Type.h6>{this.state.errorMessage}</Type.h6>
                    </NanoFlex>
                  )}
                  {this.state.passwordsNotMatching && (
                    <NanoFlex className="errorMessagePasswords">
                      <Type.h6>As Passwords não coincidem</Type.h6>
                    </NanoFlex>
                  )}
                </NanoFlex>
                <NanoFlex className="submitContainer">
                  <ButtonCTA darkBlue label="Confirmar" type={"submit"} />
                </NanoFlex>
                <NanoFlex className="backToLoginBtn">
                  <Link to="/login">
                    <Type.h4>
                      <b>Voltar ao Login</b>
                    </Type.h4>
                  </Link>
                </NanoFlex>
              </NanoFlex>
            </form>
          </NanoFlex>
          {/* END LOGIN */}

          <NanoFlex className="poweredWrapper" justifyContent="flex-end" alignItems="flex-end">
            <NanoFlex className="poweredBy" justifyContent="flex-end" alignItems="flex-end">
              <a href="https://www.medialog.pt" target="_blank" rel="noopener noreferrer">
                <PoweredBy />
              </a>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledRecoverPassword>
    );
  }
}
