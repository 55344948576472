import React from "react";
import { Component } from "react";
import styled from "styled-components";

import NanoFlex from "../../Global/NanoFlex.js";
import Input from "../../Elements/Forms/Input.js";

import { ReactComponent as Search } from "./../../Global/icons/search.svg";

const StyledSearchBar = styled(NanoFlex)`
  height: auto;

  .searchBarWrapper {
    position: relative;
    .inputContainer {
      margin: 0;
    }
    input {
      border: 1px solid ${(props) => props.theme.color.greyPalette.grey};
      padding: 10px 52px 10px 16px;
      width: 100%;
      font-size: 16px;
      color: ${(props) => props.theme.color.main.font};
      font-family: ${(props) => props.theme.font};
      border-radius: 50px;

      &::placeholder {
        font-family: ${(props) => props.theme.font};
        color: ${(props) => props.theme.color.greyPalette.grey};
      }

      &:focus {
        border: 1px solid ${(props) => props.theme.color.main.font};
      }
    }
    .searchIconWrapper {
      width: auto;
      position: absolute;
      right: 16px;
      svg {
        height: 22px;
        cursor: pointer;
        path {
          fill: ${(props) => props.theme.color.greyPalette.grey};
        }
      }
    }
  }
`;

class SearchBar extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <StyledSearchBar className="searchBarContainer" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
        <NanoFlex className="searchBarWrapper">
          <Input placeholder={this.props.placeholder} defaultValue={this.props.value} onChangeAction={this.props.onChangeAction} />
          <NanoFlex className="searchIconWrapper">
            <Search />
          </NanoFlex>
        </NanoFlex>
      </StyledSearchBar>
    );
  }
}

// Set default props
SearchBar.defaultProps = {
  placeholder: "placeholder",
};

export default SearchBar;
