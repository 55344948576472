import React, { Component } from "react";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";

// IMAGES
import { ReactComponent as ArrowBack } from "../../Global/icons/arrowBack.svg";

const ButtonBackListStyled = styled(NanoFlex)`
  height: auto;
  .backListWrapper {
    padding: 24px;
    .iconWrapper {
      width: auto;
      margin-right: 8px;
      svg {
        height: 14px;
        width: auto;
      }
    }
    .textWrapper {
      width: auto;
    }
  }
`;

const ButtonBackList = (props) => {
  return (
    <ButtonBackListStyled className="backListContainer" justifyContent="flex-start">
      <NanoFlex className="backListWrapper" justifyContent="flex-start">
        <NanoFlex className="iconWrapper">
          <ArrowBack />
        </NanoFlex>
        <NanoFlex className="textWrapper">
          <Type.p>
            <b>Voltar à Lista</b>
          </Type.p>
        </NanoFlex>
      </NanoFlex>
    </ButtonBackListStyled>
  );
};

export default ButtonBackList;
