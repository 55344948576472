import React, { Component } from "react";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import RenderRoute from "./components/Global/RenderRoute.js";
import PrivateRoute from "./components/Global/PrivateRoute.js";
import { validateVersion } from "./components/Global/tools.js";

//Layout
import { Layout } from "./components/Views/Shared/Layout.js";
//Views
import { Guidelines } from "./components/Views/Guidelines/Guidelines.js";
import { Home } from "./components/Views/Home/Home.js";
import { Login } from "./components/Views/Account/Login.js";
import { ForgotPassword } from "./components/Views/Account/ForgotPassword";
import { RecoverPassword } from "./components/Views/Account/RecoverPassword";
import { Search } from "./components/Views/Search/Search.js";
import { Share } from "./components/Views/Share/Share.js";

/* Themes */
import defaultTheme from "./themes/default.json";

import "./styles/reset.scss";
import "./styles/config.scss";

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sendToLogin: false,
    };
  }

  componentDidMount() {
    window.addEventListener("sendToLogin", this.sendToLoginEvent, false);
    validateVersion();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.sendToLogin !== this.state.sendToLogin) {
      this.setState({ sendToLogin: false });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("sendToLogin", this.sendToLoginEvent, false);
  }

  sendToLoginEvent = () => {
    this.setState({ sendToLogin: true });
  };

  render() {
    return (
      <ThemeProvider theme={defaultTheme}>
        <BrowserRouter>
          <Switch>
            {/* LOGIN */}
            <RenderRoute exact path="/login">
              <Login />
            </RenderRoute>
            {/* LOGIN */}

            {/* FORGOT PASSWORD */}
            <RenderRoute exact path="/forgotPassword">
              <ForgotPassword />
            </RenderRoute>
            {/* FORGOT PASSWORD*/}

            {/* RECOVER PASSWORD */}
            <RenderRoute path="/Account/ResetPassword">
              <RecoverPassword />
            </RenderRoute>
            {/* RECOVER PASSWORD */}

            {this.state.sendToLogin && <Redirect to="/login" />}
            {/* HOME */}
            <PrivateRoute exact path="/">
              <Home />
            </PrivateRoute>
            {/* HOME */}

            {/* SEARCH */}
            <PrivateRoute exact path="/search">
              <Layout>
                <Search />
              </Layout>
            </PrivateRoute>
            {/* SEARCH */}

            {/* SHARE */}
            <PrivateRoute exact path="/share">
              <Layout>
                <Share />
              </Layout>
            </PrivateRoute>
            {/* SHARE */}

            {window.location.hostname === "localhost" && (
              <RenderRoute exact path="/guidelines">
                <Guidelines />
              </RenderRoute>
            )}
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    );
  }
}
