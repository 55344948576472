import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";

// COMPONENTS
import ButtonIcon from "../../Elements/Buttons/ButtonIcon.js";
// IMAGES
import { ReactComponent as MLogIcon } from "../../Global/icons/iconMlog.svg";
import Logout from "../../Global/icons/logout.svg";

const StyledNavBar = styled(NanoFlex)`
  height: auto;
  .navBarWrapper {
    padding: 24px 24px 12px 24px;
    .logoWrapper {
      svg {
        height: 42px;
        width: auto;
      }
    }
    .asideWrapper {
      width: auto;
      .buttonIcon {
        .iconWrapper {
          img {
            margin-left: 3px;
          }
        }
      }
    }
  }
`;

class NavBar extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <StyledNavBar className="navBarContainer">
        <NanoFlex className="navBarWrapper" justifyContent="space-between">
          <NanoFlex className="asideWrapper logoWrapper">
            <Link to="/">
              <MLogIcon />
            </Link>
          </NanoFlex>
          <NanoFlex className="asideWrapper">
            <Link to="/login">
              <ButtonIcon white small icon={Logout} />
            </Link>
          </NanoFlex>
        </NanoFlex>
      </StyledNavBar>
    );
  }
}

export default NavBar;
