import React, { Component } from "react";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";

// COMPONENTS
import NavBar from "../Shared/NavBar.js";

// IMAGES
import backgroundImage from "../../Global/images/bckSearch.svg";

const StyledLayout = styled(NanoFlex)`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: ${(props) => props.theme.color.greyPalette.lighterGrey};
  background-image: url(${backgroundImage});
  background-repeat: no-repeat, repeat;
  background-size: cover;
  .navBar {
    height: auto;
  }
`;

export class Layout extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <StyledLayout flexDirection={"column"} alignItems={"flex-start"} alignContent={"flex-start"} justifyContent={"flex-start"}>
        <NanoFlex className="navBar">
          <NavBar />
        </NanoFlex>
        <NanoFlex className="bodyContainer">{React.cloneElement(this.props.children, { render: this.props.render })}</NanoFlex>
      </StyledLayout>
    );
  }
}
