import React, { Component } from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";

// COMPONENTS
import MiniUserCard from "../../Elements/Cards/MiniUserCard.js";
import ButtonCTA from "../../Elements/Buttons/ButtonCTA.js";
import ButtonBackList from "../../Elements/Buttons/ButtonBackList.js";
import Input from "../../Elements/Forms/Input.js";
import Textarea from "../../Elements/Forms/Textarea.js";
import SharePopUp from "../Share/SharePopUp.js";

// IMAGES

const StyledShare = styled(NanoFlex)`
  .shareWrapper {
    .toShareUsersWrapper {
      .viewTitle {
        height: auto;
        padding: 0 24px 16px 24px;
        border-bottom: 1px solid ${(props) => props.theme.color.greyPalette.grey};
        h4 {
          text-transform: uppercase;
        }
      }
      .toShareUsersList {
        background-color: ${(props) => props.theme.color.greyPalette.lighterGrey};
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        padding: 8px 0;
        height: auto;
        .toShareInfo {
          height: auto;
          padding: 0 24px;
        }
        .listShareWrapper {
          overflow: hidden;
          padding: 8px 24px 14px 24px;
        }
      }
    }

    .formContainer {
      padding: 24px;

      .mainForm {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        padding: 16px;
        background-color: ${(props) => props.theme.color.greyPalette.white};
        .formWrapper {
          .TextAreaContainer {
            .TextAreaWrapper {
              textarea {
                height: calc(100vh - 490px);
              }
            }
          }
        }
      }
    }

    .bottomContainer {
      .bottomBarWrapper {
        height: auto;
        padding: 16px 24px;
        background-color: ${(props) => props.theme.color.greyPalette.white};
        box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 32px 32px 0 0;
        -webkit-animation: scale-up-ver-bottom 0.4s ease-in-out;
        animation: scale-up-ver-bottom 0.4s ease-in-out;
        .asideBottomBar {
          width: auto;
          .btnWrapper {
            width: auto;
            margin-right: 16px;
            .backListContainer {
              .backListWrapper {
                padding: 0;
              }
            }
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    .sharePopUpWrapper {
      height: auto;
      position: absolute;
      bottom: 0;
      z-index: 1;
      display: none;
    }

    /* ANIMATION */
    @-webkit-keyframes scale-up-ver-bottom {
      0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
      }
      100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
      }
    }
    @keyframes scale-up-ver-bottom {
      0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
      }
      100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
      }
    }
    /* END ANIMATION */
  }
`;

export class Share extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledShare className="shareContainer" flexDirection="column" justifyContent="flex-start">
        <NanoFlex className="shareWrapper" flexDirection="column" justifyContent="space-between">
          <NanoFlex className="mainWrapper" flexDirection="column">
            <NanoFlex className="toShareUsersWrapper" flexDirection="column">
              <NanoFlex className="viewTitle">
                <Type.h4>
                  <b>Partilhar</b>
                </Type.h4>
              </NanoFlex>
              <NanoFlex className="toShareUsersList" flexDirection="column">
                <NanoFlex className="toShareInfo" justifyContent="flex-start" alignItems="flex-start">
                  <Type.h6>
                    A partilhar <b>3</b> perfis
                  </Type.h6>
                </NanoFlex>
                <NanoFlex className="listShareWrapper" justifyContent="flex-start">
                  <MiniUserCard />
                  {/* <MiniUserCard />
                  <MiniUserCard />
                  <MiniUserCard /> */}
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>

            {/* FORM */}
            <NanoFlex className="formContainer" flexDirection="column">
              <NanoFlex className="mainForm" flexDirection="column">
                <NanoFlex className="formWrapper">
                  <Input title="Assunto" placeholder="Insira o assunto..." error={true} onChangeAction={(currentValue) => console.log("onChangeAction", currentValue)} />
                </NanoFlex>
                <NanoFlex className="formWrapper">
                  <Textarea title="Mensagem" placeholder="Insira uma mensagem..." />
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
            {/* END FORM */}

            <NanoFlex className="bottomContainer" flexDirection="column">
              <NanoFlex className="bottomBarWrapper" justifyContent="space-between">
                <NanoFlex className="asideBottomBar">
                  <NanoFlex className="btnWrapper">
                    <ButtonBackList />
                  </NanoFlex>
                </NanoFlex>
                <NanoFlex className="asideBottomBar">
                  <NanoFlex className="btnWrapper">
                    <ButtonCTA smallCta label="Enviar" />
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>

          {/* SHARE POPUP */}
          {/* Está display none no CSS */}
          <NanoFlex className="sharePopUpWrapper">
            <SharePopUp />
          </NanoFlex>
          {/* END SHARE POPUP */}
        </NanoFlex>
      </StyledShare>
    );
  }
}
