import React from "react";
import styled from "styled-components";

import Type from "../../Global/Typography.js";
import NanoFlex from "../../Global/NanoFlex";

import check from "../../Global/icons/check.svg";
import { ReactComponent as Check } from "../../Global/icons/check.svg";

const multiple = 8;

const StyledCheckbox = styled.div`
  width: ${(props) => (props.check ? `100%` : `auto`)};
  .checkboxWrapper {
    width: 15px;
    height: 15px;
    border: ${(props) => (props.check ? `0px` : `1px solid ${(props) => props.theme.color.greyPalette.lightGrey}`)};
    cursor: pointer;
    margin-right: ${multiple + "px"};
    user-select: none;
    &:hover {
      opacity: 0.7;
    }
  }
  .dropDownLine {
    height: ${multiple * 4 + "px"}; /* 32px */
    padding: ${multiple + "px"}; /* 8px */
    user-select: none;
    background-color: ${(props) => (props.toggle ? props.theme.color.main.orange : props.theme.color.greyPalette.white)};
    /* svg {
      width: 15px;
      height: 10px;
      path {
        fill: ${(props) => (props.toggle ? props.theme.color.main.blue : props.theme.color.greyPalette.grey)};
      }
    } */
    p {
      margin-left: ${multiple + "px"}; /* 8px */
      font-weight: ${(props) => (props.toggle ? `bold` : `normal`)};
      color: ${(props) => (props.toggle ? props.theme.color.greyPalette.white : props.theme.color.main.font)};
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    /* &:hover {
      background-color: ${(props) => props.theme.color.greyPalette.lighterGrey};
    } */
  }

  .radio-outline {
    border: 1px solid ${(props) => props.theme.color.greyPalette.grey};
    width: ${multiple * 2 + "px"};
    height: ${multiple * 2 + "px"};
    padding: 3px;
    border-radius: 100%;
    cursor: pointer;
    .radio-core {
      background-color: 1px solid ${(props) => props.theme.color.greyPalette.grey};
      width: ${multiple * 2 + "px"};
      background: ${(props) => (props.toggle ? props.theme.color.main.blue : props.theme.color.greyPalette.white)};
      border-radius: 50px;
    }
  }
`;

const Checkbox = (props) => {
  if (props.check) {
    return (
      <StyledCheckbox className="checkLine" check={props.check} toggle={props.toggle}>
        <NanoFlex className="dropDownLine" justifyContent={"flex-start"} onClick={() => props.onChangeAction(props.id, props.label)}>
          {/* {props.radio ? (
            <NanoFlex className="radio-outline">
              <NanoFlex className="radio-core" />
            </NanoFlex>
          ) : (
            <Check />
          )} */}
          <Type.p title={props.label}>{props.label}</Type.p>
        </NanoFlex>
      </StyledCheckbox>
    );
  } else {
    return (
      <StyledCheckbox>
        <NanoFlex justifyContent={"flex-start"}>
          <NanoFlex className="checkboxWrapper" onClick={() => props.onChangeAction(props.id, props.label)}>
            {props.toggle && <img src={check} alt="check" />}
          </NanoFlex>
          <NanoFlex justifyContent={"flex-start"}>
            {props.labelSize === "h4" && <Type.h4 title={props.label}>{props.label}</Type.h4>}
            {props.labelSize === "h6" && <Type.p title={props.label}>{props.label}</Type.p>}
          </NanoFlex>
        </NanoFlex>
      </StyledCheckbox>
    );
  }
};

// Set default props
Checkbox.defaultProps = {
  id: 0,
  label: "",
  labelSize: "h6",
  toggle: false,
  check: false,
};

export default Checkbox;

// --- // Documentation // --- //
/*
import Checkbox from './../Nano/Checkbox.js';

<Checkbox
    id={0}
    toggle={this.state.toggleCheckbox}
    label={'Checkbox Test'}
    onChangeAction={(id) => this.setState({ toggleCheckbox: !this.state.toggleCheckbox })}
    check
/>
*/
