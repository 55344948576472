import React, { Component } from "react";
import "moment/locale/zh-cn";
import "moment/locale/en-gb";
import t from "./Global/translate.json";
//import { BaseService } from "../services/BaseService.js";

// SERVICES
import candidateService from "../services/CandidateService";

export default class BaseComponent extends Component {
  constructor(props) {
    super(props);

    this.setLanguage(this.getLanguage());

    //this._user = new BaseService().getUser();
    this._isMobile = this.isMobile();
    this._isTablet = this.isTablet();
  }

  componentDidMount() {
    window.addEventListener("languageChange", this.onLanguageChange, false);
    window.addEventListener("resize", this.onResize, false);
  }

  onLanguageChange = (e) => {
    //Set Language
    this.setLanguage(e.detail);
    //Force Component Update
    this.forceUpdate();
  };

  onResize = (e) => {
    let newIsMobile = this.isMobile();
    if (newIsMobile !== this._isMobile) {
      //Set Is Mobile
      this._isMobile = newIsMobile;
      //Force Component Update
      this.forceUpdate();
      //Trigger onIsMobileChange
      window.dispatchEvent(new Event("isMobileChange"));
    }

    let newIsTablet = this.isTablet();
    if (newIsTablet !== this._isTablet) {
      //Set Is Tablet
      this._isTablet = newIsTablet;
      //Force Component Update
      this.forceUpdate();
      //Trigger onIsTabletChange
      window.dispatchEvent(new Event("isTabletChange"));
    }
  };

  componentWillUnmount() {
    window.removeEventListener("languageChange", this.onLanguageChange, false);
    window.removeEventListener("resize", this.onResize, false);
  }

  isMobile = () => {
    return window.innerWidth <= 800;
  };

  isTablet = () => {
    return window.innerWidth <= 1280;
  };

  setLanguage = (langId) => {
    let lang = null;
    switch (langId) {
      case 1:
        lang = "ch";
        break;
      default:
        lang = "en";
    }
    //Set Local Storage Language Key
    localStorage.setItem("language", lang);
    //Set Translation Variable used on the component
    this.translation = t[lang];
  };

  getLanguage = () => {
    let lang = localStorage.getItem("language");
    switch (lang) {
      case "ch":
        return 1;
      default:
        return null;
    }
  };

  getMomentLanguage = () => {
    let lang = localStorage.getItem("language");
    switch (lang) {
      case "ch":
        return "zh-cn";
      default:
        return "en-gb";
    }
  };
}
