import React from "react";
import BaseComponent from "../../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../../Global/NanoFlex";
import Type from "../../../Global/Typography.js";

//SERVICES
import profileService from "../../../../services/ProfileService.js";

// COMPONENTS
import FilterCard from "./FilterCard.js";
import ButtonCTA from "../../../Elements/Buttons/ButtonCTA.js";
import ButtonBackList from "../../../Elements/Buttons/ButtonBackList.js";
import Input from "../../../Elements/Forms/Input.js";
import MultipleSelectionDropdown from "../../../Elements/Forms/MultipleSelectionDropdown.js";
import SingleSelectionDropdown from "../../../Elements/Forms/SingleSelectionDropdown.js";
import TreeNavigatorDropdown from "../../../Elements/Forms/TreeNavigatorDropdown.js";
import Loading from "../../../Elements/Loadings/Loading.js";

import _ from "lodash";

// IMAGES

const StyledFilters = styled(NanoFlex)`
  .filtersWrapper {
    .addedFiltersWrapper {
      height: auto;
      .viewTitle {
        height: auto;
        padding: 0 24px 16px 24px;
        border-bottom: 1px solid ${(props) => props.theme.color.greyPalette.grey};
        h4 {
          text-transform: uppercase;
        }
      }
      .addedFiltersList {
        background-color: ${(props) => props.theme.color.greyPalette.lighterGrey};
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        padding: 8px 0;
        height: auto;

        .topListHeader {
          height: auto;
          padding: 8px 24px;
          .addedFiltersInfo {
            height: auto;
          }
          .cleanFilters {
          }
        }

        .listFiltersWrapper {
          overflow: hidden;
          padding: 8px 24px 8px 24px;
          min-width: 0px;
          height: auto;
          .listDragWrapper {
            width: auto;
            cursor: -webkit-grab;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: horizontal;
          }
        }
      }
    }

    .formContainer {
      padding: 24px;

      .mainForm {
        /* height: calc(100vh - 340px); */
        max-height: calc(100vh - 340px);
        /* overflow: auto; */
      overflow-x: hidden;
      overflow-y: auto;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        padding: 16px;
        background-color: ${(props) => props.theme.color.greyPalette.white};
        .formWrapper {
          margin: 8px;
          .titleForm {
            margin-bottom: 8px;
          }
          .inputContainer {
            margin: 0;
          }
          .doubleFormWrapper {
            .inputContainer {
              width: 60px;
              margin-right: 8px;
              .inputWrapper {
                input {
                  text-align: center;
                }
              }
            }
            .inputSearchWrapper {
              .inputContainer {
                width: 100%;
                margin-right: 0;
                input {
                  text-align: left;
                }
              }
            }
            .dropDownContainer {
              width: calc(100% - 42px);
            }
          }

          .TextAreaContainer {
            .TextAreaWrapper {
              textarea {
                height: calc(100vh - 490px);
              }
            }
          }
        }
      }
      .mainFormHeigh {
max-height: calc(100vh - 289px);
      }
    }

    .bottomContainer {
      height: auto;
      .bottomBarWrapper {
        height: auto;
        padding: 16px 24px;
        background-color: ${(props) => props.theme.color.greyPalette.white};
        box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 32px 32px 0 0;
        -webkit-animation: scale-up-ver-bottom 0.4s ease-in-out;
      animation: scale-up-ver-bottom 0.4s ease-in-out;
        .asideBottomBar {
          width: auto;
          .btnWrapper {
            width: auto;
            margin-right: 16px;
            .backListContainer {
              .backListWrapper {
                padding: 0;
              }
            }
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    /* ANIMATION */
    @-webkit-keyframes scale-up-ver-bottom {
      0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
      }
      100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
      }
    }
    @keyframes scale-up-ver-bottom {
      0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
      }
      100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
      }
    }
    /* END ANIMATION */
  }
`;

class Filters extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = this.getDefaultState();

        /*Selected*/
        this.selected = this.getDefaultSelection();
    }

    getDefaultState = () => {
        return {
            termList: this.props.term?.split(" ")?.filter((item) => item) ?? [],
            searchType: this.props.searchType,

            availabilityPeriodTypes: null,
            periodTypes: null,
            languages: null,
            languageLevels: null,
            profiles: null,
            areas: null,
            specializations: null,

            availabilityPeriodDisabled: false,
            experiencePeriodDisabled: false,

            forceSelection: false,
        };
    };

    getDefaultSelection = () => {
        return {
            candidateName: "",
            location: "",
            rate: {
                min: null,
                max: null,
            },
            availability: {
                period: null,
                periodType: {
                    id: null,
                },
            },
            experience: {
                period: null,
                periodType: {
                    id: null,
                },
            },
            language: null,
            profilesIds: null,
            areasIds: null,
            skillsIds: null,
        };
    };

    componentDidMount() {
        super.componentDidMount();

        this.getAllComponentsData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.forceSelection) {
            this.setState({ forceSelection: false });
        }

        if (this.props.term !== prevProps.term) {
            this.setState({ termList: this.props.term?.split(" ")?.filter((item) => item) ?? [] });
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getAllComponentsData = () => {
        this.getAvailabilityPeriodTypes();
        this.getPeriodTypes();
        this.getLanguages();
        this.getLanguageLevels();
        this.getProfiles();
        this.getAreas();
        this.getSpecializations();
    };

    getAvailabilityPeriodTypes = () => {
        profileService
            .getAvailabilityPeriodTypes()
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
                }
            })
            .then((data) => {
                this.setState({ availabilityPeriodTypes: data });
            });
    };

    getPeriodTypes = () => {
        profileService
            .getPeriodTypes()
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
                }
            })
            .then((data) => {
                this.setState({ periodTypes: data });
            });
    };

    getLanguages = () => {
        profileService
            .getLanguages()
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
                }
            })
            .then((data) => {
                this.setState({ languages: data });
            });
    };

    getLanguageLevels = () => {
        profileService
            .getLanguageLevels()
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
                }
            })
            .then((data) => {
                this.setState({ languageLevels: data });
            });
    };

    getProfiles = () => {
        profileService
            .getProfiles()
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
                }
            })
            .then((data) => {
                this.setState({ profiles: data });
            });
    };

    getAreas = () => {
        profileService
            .getAreas()
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
                }
            })
            .then((data) => {
                this.setState({ areas: data });
            });
    };

    getSpecializations = () => {
        profileService
            .getSpecializations()
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
                }
            })
            .then((data) => {
                this.setState({ specializations: data });
            });
    };

    isComponentDataLoaded = () => {
        if (this.state.availabilityPeriodTypes === null || this.state.periodTypes === null || this.state.languages === null || this.state.languageLevels === null || this.state.profiles === null || this.state.areas === null || this.state.specializations === null) {
            return false;
        } else {
            return true;
        }
    };

    buildLanguageTreeView = () => {
        if (this.state.languages && this.state.languageLevels) {
            return this.state.languages.map((lang) => {
                return {
                    id: lang.id,
                    name: lang.name,
                    childs: this.state.languageLevels.map((langLevel) => {
                        return {
                            id: `${lang.id};${langLevel.id}`,
                            name: langLevel.name,
                        };
                    }),
                };
            });
        } else {
            return null;
        }
    };

    onClearFilters = () => {
        this.selected = this.getDefaultSelection();
        this.setState({ forceSelection: true, termList: [], openEmail: null, term: "" });
    };

    onApplyFilters = () => {
        if (this.props.onFiltersChange) {
            let term = this.state.termList.join(" ");
            if (JSON.stringify(this.selected) !== JSON.stringify(this.getDefaultSelection())) {
                var sel = JSON.parse(JSON.stringify(this.selected));

                if (!sel.availability?.period && !sel.availability?.periodType?.id) {
                    sel.availability = null;
                } else {
                    if (!sel.availability?.periodType) sel.availability.periodType = null;
                }

                if (!sel.experience?.period && !sel.experience?.periodType?.id) {
                    sel.experience = null;
                } else {
                    if (!sel.experience?.periodType) sel.experience.periodType = null;
                }

                if (!sel.rate?.min && !sel.rate?.max) sel.rate = null;

                this.props.onFiltersChange(sel, term);
            } else {
                this.props.onFiltersChange(null, term);
            }
        }
    };

    render() {
        let hasTerm = this.state.termList.length > 0;

        return (
            <StyledFilters className="filtersContainer" flexDirection="column" justifyContent="flex-start">
                {!this.isComponentDataLoaded() && <Loading />}
                {this.isComponentDataLoaded() && (
                    <NanoFlex className="filtersWrapper" flexDirection="column" justifyContent="space-between">
                        <NanoFlex className="mainWrapper" flexDirection="column">
                            <NanoFlex className="addedFiltersWrapper" flexDirection="column">
                                <NanoFlex className="viewTitle">
                                    <Type.h4>
                                        <b>Filtrar</b>
                                    </Type.h4>
                                </NanoFlex>
                                <NanoFlex className="addedFiltersList" flexDirection="column">
                                    <NanoFlex className="topListHeader" justifyContent="space-between">
                                        {hasTerm && (
                                            <NanoFlex className="addedFiltersInfo" alignItems="flex-start" justifyContent="flex-start">
                                                <Type.h6>A pesquisar por:</Type.h6>
                                            </NanoFlex>
                                        )}
                                        <NanoFlex className="cleanFilters" alignItems="flex-start" justifyContent="flex-end" onClick={this.onClearFilters}>
                                            <Type.p>Limpar Filtros</Type.p>
                                        </NanoFlex>
                                    </NanoFlex>

                                    {hasTerm && (
                                        <NanoFlex className="listFiltersWrapper" justifyContent="flex-start">
                                            <NanoFlex className="listDragWrapper" justifyContent="flex-start">
                                                {this.state.termList.map((item, index) => {
                                                    return (
                                                        <FilterCard
                                                            key={`FilterCard_${index}_${item}`}
                                                            title={item}
                                                            onDelete={() => {
                                                                this.setState({ termList: this.state.termList.filter((val, idx) => idx !== index) });
                                                            }}
                                                        />
                                                    );
                                                })}
                                            </NanoFlex>
                                        </NanoFlex>
                                    )}
                                </NanoFlex>
                            </NanoFlex>

                            {/* FORM */}
                            <NanoFlex className="formContainer" flexDirection="column">
                                <NanoFlex className={`mainForm ${hasTerm ? "" : "mainFormHeigh"}`} flexDirection="column" justifyContent="flex-start">
                                    <NanoFlex className="formWrapper">
                                        <Input title="Candidato" placeholder="Nome do Candidato..." defaultValue={this.selected.candidateName} onChangeAction={(value) => (this.selected.candidateName = value)} forceSelection={this.state.forceSelection} />
                                    </NanoFlex>
                                    <NanoFlex className="formWrapper">
                                        <Input title="Cidade de Preferência" placeholder="Cidade de Preferência..." defaultValue={this.selected.location} onChangeAction={(value) => (this.selected.location = value)} forceSelection={this.state.forceSelection} />
                                    </NanoFlex>
                                    <NanoFlex className="formWrapper">
                                        <Input title="Rate" placeholder="Rate" isNumeric={true} defaultValue={this.selected.rate.max} onChangeAction={(value) => (this.selected.rate.max = value)} forceSelection={this.state.forceSelection} />
                                    </NanoFlex>
                                    {/* <NanoFlex className="formWrapper">
                    <Input title="Estado" placeholder="Estado" onChangeAction={(value) => (this.selected.candidateName = value)} />
                  </NanoFlex> */}
                                    <NanoFlex className="formWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                                        <Type.p className="titleForm">Disponibilidade</Type.p>
                                        <NanoFlex className="doubleFormWrapper">
                                            <Input placeholder="-" isNumeric={true} defaultValue={this.selected.availability.period} disabled={this.selected.availability.periodType.id == 15} onChangeAction={(value) => (this.selected.availability.period = value)} forceSelection={this.state.forceSelection} />
                                            <SingleSelectionDropdown
                                                selected={this.selected.availability.periodType.id}
                                                options={this.state.availabilityPeriodTypes}
                                                onChangeSelection={(e) => {
                                                    this.selected.availability.periodType.id = e?.id;

                                                    if (e?.id === 15 || this.selected.availability.periodType.id === 15) {
                                                        this.selected.availability.period = null;                                                        
                                                    }

                                                    this.setState({ forceSelection: true });
                                                }}
                                                forceSelection={this.state.forceSelection}
                                            />
                                        </NanoFlex>
                                    </NanoFlex>
                                    <NanoFlex className="formWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                                        <Type.p className="titleForm">Experiência</Type.p>
                                        <NanoFlex className="doubleFormWrapper">
                                            <Input placeholder="-" isNumeric={true} defaultValue={this.selected.experience.period} disabled={this.selected.experience.periodType.id == 7} onChangeAction={(value) => (this.selected.experience.period = value)} forceSelection={this.state.forceSelection} />
                                            <SingleSelectionDropdown
                                                selected={this.selected.experience.periodType.id}
                                                options={this.state.periodTypes}
                                                onChangeSelection={(e) => {
                                                    this.selected.experience.periodType.id = e?.id;

                                                    if (e?.id === 7 || this.selected.experience.periodType.id === 7) {
                                                        this.selected.experience.period = null;
                                                    }

                                                    this.setState({ forceSelection: true });
                                                }}
                                                forceSelection={this.state.forceSelection}
                                            />
                                        </NanoFlex>
                                    </NanoFlex>
                                    <NanoFlex className="formWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                                        <Type.p className="titleForm">Língua</Type.p>
                                        <TreeNavigatorDropdown
                                            selected={this.selected.languages?.map((item) => `${item.language.id};${item.level.id}`)}
                                            onChangeSelection={(list) => console.log(list)}
                                            options={this.buildLanguageTreeView()}
                                            onChangeSelection={(e) => {
                                                this.selected.languages = e.selected.map((item) => {
                                                    let split = item.split(";");
                                                    return {
                                                        language: {
                                                            id: Number(split[0]),
                                                        },
                                                        level: {
                                                            id: Number(split[1]),
                                                        },
                                                    };
                                                });
                                            }}
                                            customDisplayString={(event) => {
                                                if (event.selectedIds.length > 0 && event.allSelected === false) {
                                                    event.handled = true;
                                                    var langIds = [...(new Set(event.selectedIds?.map((item) => Number(item.split(";")[0]))) ?? [])];
                                                    event.displayString = this.state.languages
                                                        .filter((item) => langIds.includes(item.id))
                                                        .map((item) => item.name)
                                                        .join(", ");
                                                }
                                            }}
                                            forceSelection={this.state.forceSelection}
                                        />
                                    </NanoFlex>
                                    <NanoFlex className="formWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                                        <Type.p className="titleForm">Perfil</Type.p>
                                        <MultipleSelectionDropdown selected={this.selected.profilesIds} options={this.state.profiles} onChangeSelection={(e) => (this.selected.profilesIds = e.selected.map((v) => v.id))} forceSelection={this.state.forceSelection} />
                                    </NanoFlex>
                                    <NanoFlex className="formWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                                        <Type.p className="titleForm">Técnica</Type.p>
                                        <MultipleSelectionDropdown selected={this.selected.areasIds} options={this.state.areas} onChangeSelection={(e) => (this.selected.areasIds = e.selected.map((v) => v.id))} forceSelection={this.state.forceSelection} />
                                    </NanoFlex>
                                    <NanoFlex className="formWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                                        <Type.p className="titleForm">Especialização</Type.p>
                                        <MultipleSelectionDropdown selected={this.selected.skillsIds} options={this.state.specializations} onChangeSelection={(e) => (this.selected.skillsIds = e.selected.map((v) => v.id))} forceSelection={this.state.forceSelection} />
                                    </NanoFlex>
                                </NanoFlex>
                            </NanoFlex>
                            {/* END FORM */}

                            <NanoFlex className="bottomContainer" flexDirection="column">
                                <NanoFlex className="bottomBarWrapper" justifyContent="space-between">
                                    <NanoFlex className="asideBottomBar">
                                        <NanoFlex className="btnWrapper" onClick={this.props.closeFilters}>
                                            <ButtonBackList />
                                        </NanoFlex>
                                    </NanoFlex>
                                    <NanoFlex className="asideBottomBar">
                                        <NanoFlex className="btnWrapper" onClick={this.onApplyFilters}>
                                            <ButtonCTA smallCta orange label="Filtrar" />
                                        </NanoFlex>
                                    </NanoFlex>
                                </NanoFlex>
                            </NanoFlex>
                        </NanoFlex>
                    </NanoFlex>
                )}
            </StyledFilters>
        );
    }
}

export default Filters;
