import React from "react";
import BaseComponent from "../../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../../Global/NanoFlex";
import Type from "../../../Global/Typography.js";

// COMPONENTS

// IMAGES
import readyToMatchImg from "../../../Global/images/readyToMatch.svg";

const StyledReadyToMatch = styled(NanoFlex)`
  /* height: calc(100vh - 156px); */
  height: calc(100vh - 200px);
  background-image: url(${readyToMatchImg});
  background-repeat: no-repeat, repeat;
  background-size: cover;
  background-position: center;
  .readyToMatchWrapper {
    padding-bottom: 20vh;
    h1 {
      font-size: 40px;
      line-height: 42px;
      color: ${(props) => props.theme.color.greyPalette.grey};
    }
  }
`;

class ReadyToMatch extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledReadyToMatch className="readyToMatchContainer" flexDirection="column" justifyContent="flex-start">
        <NanoFlex className="readyToMatchWrapper" flexDirection="column" justifyContent="flex-end">
          <Type.h1>
            <b>Ready to</b>
          </Type.h1>
          <Type.h1>
            <b>make a match</b>
          </Type.h1>
        </NanoFlex>
      </StyledReadyToMatch>
    );
  }
}

export default ReadyToMatch;
