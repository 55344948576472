import React from "react";
import BaseComponent from "../../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../../Global/NanoFlex";
import Type from "../../../Global/Typography.js";

// IMAGES
import { ReactComponent as Close } from "../../../Global/icons/close.svg";

const StyledFilterCard = styled(NanoFlex)`
  width: auto;
  height: auto;
  margin-right: 8px;
  .filterCardWrapper {
    width: auto;
    background-color: ${(props) => props.theme.color.greyPalette.white};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
    padding: 8px 16px;
    .infoContainer {
    }
    .deleteIcon {
      width: auto;
      margin-left: 16px;
      svg {
        width: 12px;
        height: auto;
        path {
          fill: ${(props) => props.theme.color.main.font};
        }
      }
    }
  }
  &:last-child {
    margin-right: 0;
  }
`;

class FilterCard extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledFilterCard className="filterCardContainer" flexDirection="column" alignItems="flex-start">
        <NanoFlex className="filterCardWrapper">
          <NanoFlex className="infoContainer" alignItems="flex-start">
            <Type.p>{this.props.title}</Type.p>
          </NanoFlex>
          <NanoFlex className="deleteIcon" onClick={this.props.onDelete}>
            <Close />
          </NanoFlex>
        </NanoFlex>
      </StyledFilterCard>
    );
  }
}

export default FilterCard;
