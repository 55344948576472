import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import { Popper, Reference, Manager } from "react-popper";

import Type from "../../Global/Typography.js";
import NanoFlex from "../../Global/NanoFlex.js";
import TreeNavigator from "./TreeNavigator.js";
import Checkbox from "./Checkbox.js";
import Input from "./Input.js";

import { ReactComponent as ArrowUp } from "../../Global/icons/arrowUp.svg";
import { ReactComponent as ArrowDown } from "../../Global/icons/arrowDown.svg";
import { ReactComponent as SearchIcon } from "../../Global/icons/search.svg";

const multiple = 8;
const mainWidth = multiple * 25; /* 200px */

const StyledTreeNavigatorDropdown = styled.div`
  width: 100%;
  border: 1px solid ${(props) => props.theme.color.greyPalette.grey};
  font-size: 12px;
  color: ${(props) => props.theme.color.greyPalette.grey};
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  border-radius: 12px;
  .dropDownWrapper {
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    .inputDropDownWrapper {
      width: 100%;
      height: 42px;
      .infoDropDownWrapper {
        width: calc(100% - 32px);
        padding: 0 16px;
        p {
          color: ${(props) => (props.displayString ? props.theme.color.main.font : props.theme.color.greyPalette.grey)};
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .iconDropDownWrapper {
        background-color: ${(props) => (props.indicator ? props.theme.color.main.orange : props.theme.color.main.blue)};
        width: 32px;
        padding-right: 1.5px;
        padding-top: 1.5px;
        border-radius: 0 11px 11px 0;
        svg {
          height: 15px;
          width: 15px;
        }
      }
    }
  }

  .dropDownLine {
    height: 32px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .selectAllLine {
    background-color: ${(props) => props.theme.color.greyPalette.lighterGrey};
    min-height: 32px;
  }

  .inputSearchWrapper {
    position: relative;
    min-height: -webkit-fit-content;
    div {
      margin: 0;
      width: 100%;
      input {
        border: 0;
        border-bottom: 1px solid ${(props) => props.theme.color.greyPalette.lightGrey};
        padding: ${multiple + "px"};
        padding-right: 40px;
        border-radius: 0;
      }
    }
    .searchIconWrapper {
      position: absolute;
      height: auto;
      right: ${multiple + "px"};
      width: ${multiple * 3 + "px"};
      svg {
        width: 14px;
        height: 14px;
        path {
          fill: ${(props) => props.theme.color.greyPalette.grey};
        }
      }
    }
  }
  .noResults {
    padding: ${multiple + "px"};
  }

  .dropDownContentWrapper {
    margin: 0;
    border: 1px solid ${(props) => props.theme.color.greyPalette.grey};
    width: calc(100% + 2px);
    height: auto;
    max-height: 350px;
    overflow: auto;
    background: ${(props) => props.theme.color.greyPalette.white};
    z-index: 1;
    border-radius: 12px;
  }
`;

class TreeNavigatorDropdown extends BaseComponent {
  constructor(props) {
    super(props);

    let selectedIds = this.props.selected ? this.props.selected : [];
    let options = this.props.options ? this.props.options : [];

    this.state = {
      term: "",
      options: options,
      selectedIds: selectedIds,
      isOpenDropdown: false,
      openAllTrees: false,
    };

    this.nodeRef = React.createRef();
  }

  componentDidMount() {
    super.componentDidMount();
    document.addEventListener("mousedown", this.handleOutsideClick);
    /*Prevent different IsAllSelected States from Props to State */
    this.validateIsAllSelectedProp();
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(this.state.selectedIds.sort()) !== JSON.stringify(prevState.selectedIds.sort())) {
      this.triggerOnChangeSelection();
    }

    if (prevProps.selected !== this.props.selected || this.props.forceSelection) {
      this.setState({ selectedIds: this.props.selected ? this.props.selected : [] }, () => {
        /*Remove Term if Value changed and Dropdown is closed*/
        if (!this.state.isOpenDropdown) {
          this.onTermChange("");
        }
      });
      this.validateIsAllSelectedProp();
    }

    if (JSON.stringify(this.props.options.sort()) !== JSON.stringify(prevProps.options.sort())) {
      let selectedIds = this.getFlattenOptions(this.props.options)
        .filter((v) => {
          return this.state.selectedIds.includes(v.id);
        })
        .map((v) => {
          return v.id;
        });

      this.setState({ options: this.props.options, selectedIds: selectedIds });
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    document.removeEventListener("mousedown", this.handleOutsideClick);
  }

  handleOutsideClick = (e) => {
    if (!this.nodeRef.current.contains(e.target)) this.setState({ isOpenDropdown: false });
  };

  validateIsAllSelectedProp = () => {
    if (this.props.isAllSelected === true) {
      this.setState({
        selectedIds: this.getFlattenOptions(this.state.options).map((v) => {
          return v.id;
        }),
      });
    }
  };

  triggerOnChangeSelection = () => {
    if (this.props.onChangeSelection) {
      this.props.onChangeSelection({
        isAllSelected: this.getIsAllSelected(this.state.options, this.state.selectedIds),
        selected: this.state.selectedIds,
      });
    }
  };

  /*Helpers*/
  getFlattenOptions = (options) => {
    return options
      .map((v) => {
        return v.childs;
      })
      .flat();
  };

  getIsAllSelected = (options, selectedIds) => {
    return this.getFlattenOptions(options).length === selectedIds.length;
  };

  getGroupSelectedChilds = (group) => {
    return group.childs.filter((v) => {
      return this.state.selectedIds.includes(v.id);
    });
  };

  getGroupIsAllSelected = (group) => {
    let filterGroup = this.getGroupSelectedChilds(group);
    return filterGroup.length === group.childs.length;
  };

  getDisplayString = () => {
    let flattenOptions = this.getFlattenOptions(this.state.options);
    let allSelected = this.state.selectedIds.length === flattenOptions.length;
    /*Add Custom Display String*/
    if (this.props.customDisplayString) {
      var event = {
        handled: false,
        selectedIds: [...this.state.selectedIds],
        displayString: null,
        allSelected: allSelected,
      };
      this.props.customDisplayString(event);
      if (event.handled) return event.displayString;
    }
    if (this.state.selectedIds.length === 0) return "";
    if (allSelected) return "Todas as opções selecionadas";

    let selectedJoin = flattenOptions
      .filter((v) => {
        return this.state.selectedIds.includes(v.id);
      })
      .map((v) => {
        return v.name;
      })
      .join(", ");

    if (selectedJoin.length <= 30) return selectedJoin;

    return `${this.state.selectedIds.length} Selecionadas`;
  };

  /*Events*/
  onSelectAllClick = () => {
    /*Get Filtered Results and ids */
    let results = this.getFilteredResults();
    let resultsIds = this.getFlattenOptions(results).map((v) => v.id);
    let filteredSelectedIds = this.getFilteredSelectedIds();
    /*Validate if is All Selected */
    if (this.getIsAllSelected(results, filteredSelectedIds)) {
      /*Get Ids Excluding current filtered results ids */
      let excludedSelectedIds = this.state.selectedIds.filter((v) => {
        return resultsIds.includes(v) == false;
      });
      this.setState({ selectedIds: excludedSelectedIds });
    } else {
      /*Merge filtered results ids and state selected ids and Distinct */
      let selectedIds = [...new Set([...resultsIds, ...this.state.selectedIds])];
      this.setState({
        selectedIds: selectedIds,
      });
    }
  };

  onGroupClick = (group) => {
    let groupIds = group.childs.map((v) => v.id);

    let selectedIdsWithoutGroup = this.state.selectedIds.filter((v) => {
      return groupIds.includes(v) == false;
    });

    if (this.getGroupIsAllSelected(group)) {
      this.setState({ selectedIds: selectedIdsWithoutGroup });
    } else {
      this.setState({ selectedIds: [...selectedIdsWithoutGroup, ...groupIds] });
    }
  };

  onItemClick = (item) => {
    if (this.state.selectedIds.includes(item.id)) {
      this.setState({
        selectedIds: this.state.selectedIds.filter((id) => {
          return id !== item.id;
        }),
      });
    } else {
      this.setState({ selectedIds: [...this.state.selectedIds, ...[item.id]] });
    }
  };

  onTermChange = (value) => {
    var openAllTrees = false;
    if (value && value.trim() !== "") {
      openAllTrees = true;
    }

    this.setState({ term: value, openAllTrees: openAllTrees });
  };

  getFilteredResults = () => {
    if (this.state.term.trim() !== "") {
      let upperTerm = this.state.term.trim().toUpperCase();
      let options = JSON.parse(JSON.stringify(this.state.options));

      options.forEach((v) => {
        /*Filter Childs by Term */
        v.childs = v.childs.filter((v) => {
          let upperName = v.name.trim().toUpperCase();
          return upperName.includes(upperTerm);
        });
      });

      /*Only return options with group values */
      return options.filter((v) => {
        return v.childs.length > 0;
      });
    } else {
      return this.state.options;
    }
  };

  getFilteredSelectedIds = () => {
    let filteredResults = this.getFilteredResults();
    let resultsIds = this.getFlattenOptions(filteredResults).map((v) => v.id);
    let filteredSelectedIds = this.state.selectedIds.filter((v) => {
      return resultsIds.includes(v);
    });
    return filteredSelectedIds;
  };

  render() {
    let displayString = this.getDisplayString();
    let filteredResults = this.getFilteredResults();
    return (
      <StyledTreeNavigatorDropdown ref={this.nodeRef} displayString={displayString} indicator={this.state.selectedIds.length > 0}>
        {this.state.options && (
          <NanoFlex className="dropDownWrapper">
            {this.props.title && <Type.p className="title">{this.props.title}</Type.p>}

            <Manager>
              <Reference>
                {(targetProps) => {
                  return (
                    <NanoFlex
                      ref={targetProps.ref}
                      className="inputDropDownWrapper"
                      justifyContent={"flex-start"}
                      alignItems={"flex-start"}
                      onClick={() => {
                        this.setState({ isOpenDropdown: !this.state.isOpenDropdown });
                      }}>
                      <NanoFlex className="infoDropDownWrapper" justifyContent={"flex-start"}>
                        <Type.p>{displayString ? displayString : "Selecione uma opção"}</Type.p>
                      </NanoFlex>
                      <NanoFlex className="iconDropDownWrapper">{this.state.isOpenDropdown ? <ArrowUp /> : <ArrowDown className="svgMargin" />}</NanoFlex>
                    </NanoFlex>
                  );
                }}
              </Reference>
              {this.state.isOpenDropdown && (
                <Popper placement={"bottom"}>
                  {({ ref, style, placement, arrowProps }) => (
                    <NanoFlex ref={ref} style={style} data-placement={placement} className="dropDownContentWrapper" flexDirection={"column"} justifyContent="flex-start" alignItems="flex-start">
                      <NanoFlex className="inputSearchWrapper">
                        <Input title={null} defaultValue={this.state.term} placeholder={"Pesquisar" + "..."} onChangeAction={this.onTermChange} />
                        <NanoFlex className="searchIconWrapper">
                          <SearchIcon />
                        </NanoFlex>
                      </NanoFlex>
                      {filteredResults.length == 0 && (
                        <NanoFlex className="noResults">
                          <Type.p>Sem Resultados</Type.p>
                        </NanoFlex>
                      )}
                      {filteredResults.length > 0 && (
                        <NanoFlex className="dropDownLine selectAllLine" justifyContent="flex-start" alignItems="flex-start">
                          <Checkbox check id={1000000} toggle={this.getIsAllSelected(filteredResults, this.getFilteredSelectedIds())} label={"Selecionar tudo"} labelSize={"h4"} onChangeAction={this.onSelectAllClick} />
                        </NanoFlex>
                      )}
                      {filteredResults.map((group) => {
                        let selectedChilds = this.getGroupSelectedChilds(group);
                        let isGroupAllSelected = this.getGroupIsAllSelected(group);
                        return (
                          <TreeNavigator
                            key={`Group_${group.id}`}
                            indicator={selectedChilds.length > 0 ? true : false}
                            isOpen={this.state.openAllTrees}
                            main={
                              <NanoFlex className="dropDownLine" justifyContent="flex-start">
                                <Checkbox check id={`Group_Check_${group.id}`} toggle={isGroupAllSelected} label={group.name} labelSize={"h4"} onChangeAction={(id) => this.onGroupClick(group)} />
                              </NanoFlex>
                            }
                            content={
                              <div>
                                {group.childs.map((item) => {
                                  return (
                                    <NanoFlex key={`Item_${item.id}`} className="dropDownLine" justifyContent="flex-start" alignItems="flex-start">
                                      <Checkbox check id={`Item_Check_${item.id}`} toggle={isGroupAllSelected ? isGroupAllSelected : selectedChilds.includes(item)} label={item.name} labelSize={"h4"} onChangeAction={(id) => this.onItemClick(item)} />
                                    </NanoFlex>
                                  );
                                })}
                              </div>
                            }
                          />
                        );
                      })}
                    </NanoFlex>
                  )}
                </Popper>
              )}
            </Manager>
          </NanoFlex>
        )}
      </StyledTreeNavigatorDropdown>
    );
  }
}

// Set default props
TreeNavigatorDropdown.defaultProps = {
  options: null,
};

export default TreeNavigatorDropdown;

// --- // Documentation // --- //
/*
import TreeNavigatorDropdown from './../Nano/TreeNavigatorDropdown.js';

<TreeNavigatorDropdown
    options={array}
    selected={array}
    onChangeSelection={(options) => console.log(options)}
/>
*/
