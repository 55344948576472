import React from "react";
import BaseComponent from "../../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../../Global/NanoFlex";

// SERVICES
import candidateService from "../../../../services/CandidateService";

// COMPONENTS
import CardProfile from "./CardProfile.js";
import EmailPopUp from "./EmailPopUp.js";
import ButtonIcon from "../../../Elements/Buttons/ButtonIcon.js";
import ButtonBackList from "../../../Elements/Buttons/ButtonBackList.js";
import Loading from "../../../Elements/Loadings/Loading.js";

// IMAGES
import Download from "../../../Global/icons/download.svg";
import Email from "../../../Global/icons/email.svg";
import Share from "../../../Global/icons/share.svg";
import Phone from "../../../Global/icons/phone.svg";

const StyledProfile = styled(NanoFlex)`
  .profileWrapper {
    /* height: auto; */
    .cardProfile {
      padding: 0px 24px;
    }

    .bottomContainer {
      height: auto;
      .backListBtnWrapper {
        height: auto;
      }
      .bottomBarWrapper {
        height: auto;
        padding: 16px 24px;
        background-color: ${(props) => props.theme.color.greyPalette.white};
        box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 32px 32px 0 0;
        -webkit-animation: scale-up-ver-bottom 0.4s ease-in-out;
      animation: scale-up-ver-bottom 0.4s ease-in-out;
        .asideBottomBar {
          width: auto;
          .btnWrapper {
            width: auto;
            margin-right: 16px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    .emailPopUpWrapper {
      height: auto;
      position: absolute;
      bottom: 0;
      z-index: 1;
    }

    /* ANIMATION */
    @-webkit-keyframes scale-up-ver-bottom {
      0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
      }
      100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
      }
    }
    @keyframes scale-up-ver-bottom {
      0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
      }
      100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
      }
    }
    /* END ANIMATION */
  }
`;

export default class Profile extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      openEmail: false,
      loading: false,
      cvFileBlob: null,
    };

    this.canShare = navigator.share ? true : false;
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  onDownloadCv = () => {
    this.setState({ loading: true }, () => {
      candidateService
        .downloadCandidateReport(this.props.candidateId)
        .then(() => {
          this.setState({ loading: false });
        });
    });
  };

  onShare = () => {
    if (this.canShare === true) {
      this.setState({ doingStuff: true }, () => {
        candidateService
          .getCandidatesReportFileUrl(this.props.candidateId.toString())
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
          })
          .then((fileUrl) => {
            if (fileUrl) {
              let data = {
                url: fileUrl,
                text: "Test",
                title: "Share",
              };

              navigator
                .share(data)
                .catch((error) => {
                  console.log(error);
                });
            }
          });

        candidateService
          .generateCandidatesReport(this.props.candidateId.toString())
          .then(() => {
            this.setState({ doingStuff: false });
          });
      });
    }
  };

  onPhone = () => {
    candidateService
      .getCandidatePhone(this.props.candidateId)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((phone) => {
        if (phone) {
          console.log(phone);
          window.open("tel:" + phone, "_self");
        }
      });
  };

  render() {
    return (
      <StyledProfile
        className="profileContainer"
        flexDirection="column"
        justifyContent="flex-start"
      >
        {this.state.loading && <Loading />}
        {!this.state.loading && (
          <NanoFlex
            className="profileWrapper"
            flexDirection="column"
            justifyContent="space-between"
          >
            <NanoFlex className="mainWrapper" flexDirection="column">
              <NanoFlex className="cardProfile">
                <CardProfile
                  candidateId={this.props.candidateId}
                  matchPercentage={this.props.matchPercentage}
                />
              </NanoFlex>

              <NanoFlex className="bottomContainer" flexDirection="column">
                <NanoFlex
                  className="backListBtnWrapper"
                  onClick={this.props.backToList}
                >
                  <ButtonBackList />
                </NanoFlex>

                <NanoFlex
                  className="bottomBarWrapper"
                  justifyContent="space-between"
                >
                  <NanoFlex className="asideBottomBar">
                    <NanoFlex
                      className="btnWrapper"
                      onClick={() => {
                        this.setState({ openEmail: true });
                      }}
                    >
                      <ButtonIcon icon={Email} />
                    </NanoFlex>
                    <NanoFlex
                      className="btnWrapper"
                      onClick={() => {
                        this.onDownloadCv();
                      }}
                    >
                      <ButtonIcon icon={Download} />
                    </NanoFlex>
                    <NanoFlex
                      className="btnWrapper"
                      onClick={() => {
                        this.onPhone();
                      }}
                    >
                      <ButtonIcon icon={Phone} />
                    </NanoFlex>
                  </NanoFlex>
                  {/* {this.canShare && (
                    <NanoFlex className="asideBottomBar">
                      <NanoFlex
                        className="btnWrapper"
                        onClick={() => {
                          this.onShare();
                        }}
                      >
                        <ButtonIcon blue icon={Share} />
                      </NanoFlex>
                    </NanoFlex>
                  )} */}
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
            {/* EMAIL POPUP */}
            {this.state.openEmail && (
              <NanoFlex className="emailPopUpWrapper">
                <EmailPopUp
                  closeEmail={() => {
                    this.setState({ openEmail: false });
                  }}
                  candidateId={this.props.candidateId}
                />
              </NanoFlex>
            )}
            {/* END EMAIL POPUP */}
          </NanoFlex>
        )}
      </StyledProfile>
    );
  }
}
