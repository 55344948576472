import React, { Component } from "react";
import { Link } from "react-router-dom";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";

//SERVICES
import accountService from "../../../services/AccountService.js";

// COMPONENTS
import Loading from "../../Elements/Loadings/Loading.js";
import ButtonCTA from "../../Elements/Buttons/ButtonCTA.js";
import Input from "../../Elements/Forms/Input.js";

// IMAGES
import { ReactComponent as MlogLogo } from "../../Global/images/mlogLogoSignature.svg";
import { ReactComponent as PoweredBy } from "../../Global/icons/poweredBy.svg";
import { ReactComponent as Check } from "../../Global/icons/check.svg";
import { ReactComponent as Alert } from "../../Global/icons/alert.svg";

const StyledLogin = styled(NanoFlex)`
  background-color: ${(props) => props.theme.color.greyPalette.lightGrey};
  height: 100vh;
  width: 100vw;

.mainLogin {
  height: 100vh;
  width: 100vw;
  background: linear-gradient(0deg, #0b679f 0%, #ffffff 100%);
}

  .logoWrapper {
    height: 20vh;
    padding-top: 5vh;
    svg {
      width: auto;
      height: 100%;
    }
  }

  .loginContainer {
    height: calc(100vh - 25vh);
    position: relative;

    .bckWrapper {
      position: fixed;
      height: 100%;
      width: 100%;

      .bckObject {
        width: 100%;
        height: 100%;
        transform: skew(0deg, -6deg);
        background-color: ${(props) => props.theme.color.main.blue};
        border-radius: 55px;
        position: absolute;
        top: 20px;
      }
      .bckObject2 {
        width: 100%;
        height: 100%;
        transform: skew(0deg, -6deg) rotate(-6deg);
        background-color: ${(props) => props.theme.color.main.blue};
        opacity: 0.4;
        border-radius: 55px;
      }
    }

    /* LOGIN */
    .loginWrapper {
      padding: 60px 20px 20px 20px;
      position: relative;
      .form {
        width: 100%;
        a {
          text-decoration: none;
        }
      }
    }
    /* END LOGIN */

    .formWrapper {
      padding: 20px 5vw;
      position: relative;
      z-index: 2;
      height: auto;
      .headerForm {
        height: auto;
        margin-bottom: 32px;
        h2 {
          b {
            color: ${(props) => props.theme.color.greyPalette.white};
          }
        }
      }

      .messageWrapper {
        .iconMessageWrapper {
          margin-bottom: 24px;
          svg {
            height: 80px;
            width: auto;
            path {
              fill: ${(props) => props.theme.color.greyPalette.white};
            }
          }
        }
        .messageContainer {
          text-align: center;
          h5 {
            margin-bottom: 16px;
            color: ${(props) => props.theme.color.greyPalette.white};
            text-transform: uppercase;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      .submitContainer {
        margin-top: 24px;
      }

      .passwordForgotContainer {
        margin-top: 24px;
        p {
          text-align: center;
          cursor: pointer;
          color: ${(props) => props.theme.color.greyPalette.white};
        }
      }

      .backToLoginBtn {
        margin-top: 32px;
        h4 {
          text-transform: uppercase;
          text-align: center;
          cursor: pointer;
          color: ${(props) => props.theme.color.greyPalette.white};
        }
      }
    }

    .poweredWrapper {
      position: relative;
      z-index: 2;
      height: auto;
      padding: 20px;
      .poweredBy {
        width: 30vw;
        svg {
          width: auto;
          height: auto;
        }
      }
    }
  }

  .inputContainer {
    .title {
      color: ${(props) => props.theme.color.greyPalette.white};
    }
  }
`;

export class Login extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      username: "",
      password: "",
      error: false,
      loading: false
    };
  }

  componentDidMount() {
    super.componentDidMount();

    if (accountService.getToken() !== null) {
      /*Remove User From LocalStorage */
      accountService.removeToken();
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  login = (event) => {
    // Prevent page refresh
    event.preventDefault();

    this.setState({ loading: true }, () => {
      accountService
      .authenticate(this.state.username, this.state.password)
      .then((response) => {
        if (!response.ok || response.status === 204) {
          this.setState({ error: true, loading: false });
        } else {
          if (response.ok) {
            return response.json();
          }
        }
      })
      .then((data) => {
        if (data) {
          // Set User
          accountService.setToken(data);
          // Redirect to Homepage
          this.props.render.history.push("/");
        }
      });
    });
  };

  render() {
    return (

      <StyledLogin flexDirection="column" justifyContent="space-between">
             {this.state.loading && <Loading />}
        {/* LOGIN */}
        {!this.state.loading &&
        <NanoFlex className="mainLogin" flexDirection="column" justifyContent="space-between">
          <NanoFlex className="logoWrapper">
        <MlogLogo />
      </NanoFlex>
      <NanoFlex className="loginContainer" flexDirection="column" justifyContent="space-between">
        <NanoFlex className="bckWrapper">
          <NanoFlex className="bckObject"></NanoFlex>
          <NanoFlex className="bckObject2"></NanoFlex>
        </NanoFlex>

   

        
        <NanoFlex className="loginWrapper" flexDirection="column" justifyContent="space-between">
          <form className="form" onSubmit={this.login}>
            <NanoFlex className="formWrapper" flexDirection="column">
              <NanoFlex className="headerForm">
                <Type.h2>
                  <b>INICIAR SESSÃO</b>
                </Type.h2>
              </NanoFlex>
              <NanoFlex className="formContainer" flexDirection="column">
                <Input title="Utilizador" placeholder="Utilizador" error={this.state.error} onChangeAction={(value) => this.setState({ username: value })} />
                <Input title="Password" placeholder="Password" eye={true} error={this.state.error} onChangeAction={(value) => this.setState({ password: value })} />
              </NanoFlex>
              <NanoFlex className="submitContainer">
                <ButtonCTA darkBlue label="Iniciar Sessão" type={"submit"} />
              </NanoFlex>
              <NanoFlex className="passwordForgotContainer">
                <Link to="/forgotPassword">
                  <Type.p>Esqueceu-se da password?</Type.p>
                </Link>
              </NanoFlex>
            </NanoFlex>
          </form>
        </NanoFlex>

        <NanoFlex className="poweredWrapper" justifyContent="flex-end" alignItems="flex-end">
          <NanoFlex className="poweredBy" justifyContent="flex-end" alignItems="flex-end">
            <a href="https://www.medialog.pt" target="_blank" rel="noopener noreferrer">
              <PoweredBy />
            </a>
          </NanoFlex>
        </NanoFlex>
      </NanoFlex>
        </NanoFlex>
      }
      {/* END LOGIN */}
      
    </StyledLogin>

    );
  }
}
