import { BaseService } from "./BaseService";

export class ProfileService extends BaseService {
  getAvailabilityPeriodTypes() {
    return this.request(`Profiles/GetAvailabilityPeriodTypes`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }
  
  getPeriodTypes() {
    return this.request(`Profiles/GetPeriodTypes`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  getLanguages() {
    return this.request(`Profiles/GetLanguages`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  getLanguageLevels() {
    return this.request(`Profiles/GetLanguageLevels`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  getProfiles() {
    return this.request(`Profiles/GetProfiles`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  getAreas() {
    return this.request(`Profiles/GetAreas`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  getSpecializations() {
    return this.request(`Profiles/GetSpecializations`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }
}

const profileService = new ProfileService();

export default profileService;
