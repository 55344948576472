import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import { elementMatchesAny } from "../../Global/tools.js";
import Type from "../../Global/Typography.js";
import NanoFlex from "../../Global/NanoFlex.js";
import Input from "./Input.js";
import { Popper, Reference, Manager } from "react-popper";

import { ReactComponent as ArrowUp } from "../../Global/icons/arrowUp.svg";
import { ReactComponent as ArrowDown } from "../../Global/icons/arrowDown.svg";
import { ReactComponent as Check } from "../../Global/icons/check.svg";
import { ReactComponent as Delete } from "../../Global/icons/delete.svg";
import { ReactComponent as SearchIcon } from "../../Global/icons/search.svg";

const multiple = 8;
const StyledBlockBox = styled.div`
  width: 100%;
  height: ${multiple * 4 + "px"}; /* 32px */
  padding: ${multiple + "px"}; /* 8px */
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
  background-color: ${(props) => (props.selected ? props.theme.color.main.orange : props.theme.color.greyPalette.white)};
  .dropDownLine {
    p {
      font-weight: ${(props) => (props.selected ? `bold` : `normal`)};
      color: ${(props) => (props.selected ? props.theme.color.greyPalette.white : props.theme.color.main.font)};
      width: 100%;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .deleteIcon {
      width: 25px;
      padding: 16px 0px;
      svg {
        height: 12px;
      }
      &:hover {
        svg {
          path {
            fill: ${(props) => props.theme.color.main.red};
          }
        }
      }
    }
  }
  &:first-child {
    background-color: ${(props) => props.theme.color.greyPalette.lighterGrey};
  }
`;

const BlockBox = (props) => (
  <StyledBlockBox
    onClick={(e) => {
      /*Prevent Click on Delete Icon*/
      if (elementMatchesAny(e.target, [".deleteIcon"])) return;
      return props.blockBoxAction(props.index);
    }}
    selected={props.selected}>
    {props.content && (
      <NanoFlex className="dropDownLine" justifyContent={"flex-start"}>
        {/* <Check /> */}
        {props.customContent && props.customContent()}
        {!props.customContent && <Type.p>{props.content}</Type.p>}
        {props.delete && (
          <NanoFlex className="deleteIcon" onClick={() => props.deleteAction()}>
            <Delete />
          </NanoFlex>
        )}
      </NanoFlex>
    )}
  </StyledBlockBox>
);

const StyledSingleSelectionDropdown = styled.div`
  width: 100%;
  border: 1px solid ${(props) => props.theme.color.greyPalette.grey};
  font-size: 12px;
  color: ${(props) => props.theme.color.greyPalette.grey};
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  border-radius: 12px;
  .dropDownWrapper {
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    .inputDropDownWrapper {
      width: 100%;
      height: 42px;
      .infoDropDownWrapper {
        width: calc(100% - 32px);
        padding: 0 16px;
        p {
          color: ${(props) => (props.displayString ? props.theme.color.main.font : props.theme.color.greyPalette.grey)};
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .iconDropDownWrapper {
        background-color: ${(props) => (props.indicator ? props.theme.color.main.orange : props.theme.color.main.blue)};
        width: 32px;
        /* padding: 5px; */
        padding-right: 1.5px;
        padding-top: 1.5px;
        border-radius: 0 11px 11px 0;
        svg {
          height: 15px;
          width: 15px;
        }
      }
    }
  }

  .inputSearchWrapper {
    position: relative;
    min-height: -webkit-fit-content;
    div {
      margin: 0;
      width: 100%;
      input {
        border: 0;
        border-bottom: 1px solid ${(props) => props.theme.color.greyPalette.grey};
        padding: ${multiple + "px"};
        padding-right: 40px;
        border-radius: 0;
      }
    }
    .searchIconWrapper {
      position: absolute;
      height: auto;
      right: ${multiple + "px"};
      width: ${multiple * 3 + "px"};
      svg {
        width: 14px;
        height: 14px;
        path {
          fill: ${(props) => props.theme.color.greyPalette.grey};
        }
      }
    }
  }
  .noResults {
    padding: ${multiple + "px"};
  }

  .dropDownContentWrapper {
    margin: 0;
    border: 1px solid ${(props) => props.theme.color.greyPalette.grey};
    width: calc(100% + 2px);
    height: auto;
    max-height: 350px;
    overflow: auto;
    background: ${(props) => props.theme.color.greyPalette.white};
    z-index: 1;
    border-radius: 12px;
  }
`;

class SingleSelectionDropdown extends BaseComponent {
  constructor(props) {
    super(props);
    let options = this.props.options ? this.props.options : [];
    this.state = {
      term: "",
      options: options,
      selected: this.props.selected,
      isOpenDropdown: false,
    };

    this.nodeRef = React.createRef();
  }

  componentDidMount() {
    super.componentDidMount();
    document.addEventListener("mousedown", this.handleOutsideClick);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selected !== this.state.selected) {
      this.triggerOnChangeSelection();
    }
    if (JSON.stringify(prevProps.selected) !== JSON.stringify(this.props.selected) || this.props.forceSelection) {
      this.setState({ selected: this.props.selected });
    }
    if (prevProps.options !== this.props.options) {
      this.setState({ options: this.props.options });
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    document.removeEventListener("mousedown", this.handleOutsideClick);
  }

  handleOutsideClick = (e) => {
    if (!this.nodeRef.current.contains(e.target)) this.setState({ isOpenDropdown: false });
  };

  triggerOnChangeSelection = () => {
    if (this.props.onChangeSelection) {
      this.props.onChangeSelection(
        this.state.options.find((v) => {
          return this.state.selected == v.id;
        })
      );
    }
  };

  onOptionClick = (v) => {
    this.setState({
      selected: this.state.selected !== v ? v : null,
    });
  };

  onTermChange = (value) => {
    this.setState({ term: value });
  };

  getDisplayString = () => {
    /*Add Custom Display String*/
    if (this.props.customDisplayString) {
      var event = {
        handled: false,
        selected: this.state.selected,
        displayString: null,
      };
      this.props.customDisplayString(event);
      if (event.handled) return event.displayString;
    }
    if (!this.state.selected) return "";

    return this.state.options.find((v) => {
      return this.state.selected == v.id;
    }).name;
  };

  getFilteredResults = () => {
    if (this.state.term.trim() !== "") {
      let upperTerm = this.state.term.trim().toUpperCase();
      return this.state.options.filter((v) => {
        let upperName = v.name.trim().toUpperCase();
        return upperName.includes(upperTerm);
      });
    } else {
      return this.state.options;
    }
  };

  render() {
    let displayString = this.getDisplayString();
    let filteredResults = this.getFilteredResults();
    return (
      <StyledSingleSelectionDropdown ref={this.nodeRef} className="dropDownContainer" displayString={displayString} indicator={this.state.selected}>
        <NanoFlex className="dropDownWrapper">
          <Manager>
            <Reference>
              {(targetProps) => {
                return (
                  <NanoFlex
                    ref={targetProps.ref}
                    className="inputDropDownWrapper"
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                    onClick={() => {
                      this.setState({ isOpenDropdown: !this.state.isOpenDropdown });
                    }}>
                    <NanoFlex className="infoDropDownWrapper" justifyContent={"flex-start"}>
                      <Type.p>{displayString ? displayString : "Selecione uma opção"}</Type.p>
                    </NanoFlex>
                    <NanoFlex className="iconDropDownWrapper">{this.state.isOpenDropdown ? <ArrowUp /> : <ArrowDown className="svgMargin" />}</NanoFlex>
                  </NanoFlex>
                );
              }}
            </Reference>
            {this.state.isOpenDropdown && (
              <Popper placement={"bottom"}>
                {({ ref, style, placement, arrowProps }) => (
                  <NanoFlex ref={ref} style={style} data-placement={placement} className="dropDownContentWrapper" flexDirection={"column"} justifyContent={"flex-start"}>
                    <NanoFlex className="inputSearchWrapper">
                      <Input title={null} defaultValue={this.state.term} placeholder={"Pesquisar" + "..."} onChangeAction={this.onTermChange} />
                      <NanoFlex className="searchIconWrapper">
                        <SearchIcon />
                      </NanoFlex>
                    </NanoFlex>
                    {filteredResults.length == 0 && (
                      <NanoFlex className="noResults">
                        <Type.p>Sem Resultados</Type.p>
                      </NanoFlex>
                    )}
                    {filteredResults.map((v, i) => {
                      return (
                        <BlockBox
                          key={`${i}_${v.id}`}
                          content={v.name}
                          selected={this.state.selected === v.id}
                          blockBoxAction={() => {
                            this.onOptionClick(v.id);
                          }}
                          delete={this.props.onDeleteClick ? true : false}
                          deleteAction={() => {
                            this.props.onDeleteClick(v.id);
                          }}
                          customContent={
                            this.props.customContent
                              ? () => {
                                  return this.props.customContent(v);
                                }
                              : null
                          }
                        />
                      );
                    })}
                  </NanoFlex>
                )}
              </Popper>
            )}
          </Manager>
        </NanoFlex>
      </StyledSingleSelectionDropdown>
    );
  }
}

export default SingleSelectionDropdown;
// --- // Documentation // --- //
/*
import SingleSelectionDropdown from './../Nano/SingleSelectionDropdown.js';
<SingleSelectionDropdown
    options={ [] }
    onChangeSelection={(id) => console.log(id)}
/>
*/
