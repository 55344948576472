import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";

// COMPONENTS
import MatchChart from "./MatchChart.js";

// IMAGES
import DefaultUser from "../../Global/icons/defaultUser.svg";
import { ReactComponent as Candidate } from "../../Global/icons/candidate.svg";
import { ReactComponent as Consultant } from "../../Global/icons/collab.svg";
import { ReactComponent as Favorite } from "../../Global/icons/heart.svg";
import { ReactComponent as Location } from "../../Global/icons/location.svg";
import { ReactComponent as Briefcase } from "../../Global/icons/briefcase.svg";
import { ReactComponent as Rate } from "../../Global/icons/rate.svg";
import { ReactComponent as Check } from "../../Global/icons/check.svg";

const StyledUserCard = styled(NanoFlex)`
  /* width: auto; */
  height: auto;
  margin: 8px;
  .userCardWrapper {
    background-color: ${(props) => props.theme.color.greyPalette.white};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    overflow: hidden;
    .userInfoWrapper {
      padding: 16px;
      overflow: hidden;
      .topInfoWrapper {
        border-bottom: 1px solid ${(props) => props.theme.color.greyPalette.lightGrey};
        padding-bottom: 16px;
        .userInfo {
          overflow: hidden;
          padding-right: 8px;
          .photoWrapper {
            width: auto;
            margin-right: 8px;
            .imgWrapper {
              width: 35px;
              height: 35px;
              border-radius: 50px;
              overflow: hidden;
              img {
                width: 100%;
                min-height: 100%;
                object-fit: cover;
              }
            }
          }
          .infoContainer {
            overflow: hidden;
            p {
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              b {
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
            h6 {
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
        .userIcons {
          width: auto;
          .userIconWrapper {
            width: auto;
            margin-left: 8px;
            &:first-child {
              margin-left: 0;
            }
          }
        }
      }
      .bottomInfoWrapper {
        padding-top: 16px;
        .leftAside {
          .infoLineUser {
            margin-bottom: 4px;
            .iconInfoWrapper {
              width: 34px;
              height: 26px;
              background-color: ${(props) => props.theme.color.greyPalette.lighterGrey};
              padding: 4px;
              margin-right: 8px;
              svg {
                width: auto;
                max-width: 14px;
                height: auto;
                max-height: 14px;
                path {
                  fill: ${(props) => props.theme.color.main.blue};
                }
              }
            }
            .infoWrapper {
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        .rightAside {
          width: auto;
        }
      }
    }

    .userSelectWrapper {
      width: 42px;
      background-color: ${(props) => (props.selected ? props.theme.color.main.blue : props.theme.color.greyPalette.lighterGrey)};
      svg {
        width: 24px;
        height: auto;
        path {
          fill: ${(props) => (props.selected ? props.theme.color.greyPalette.white : props.theme.color.main.font)};
        }
      }
    }
  }
  &:first-child {
    margin-top: 0;
  }
`;

class UserCard extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }
  render() {
    return (
      <StyledUserCard className="cardContainer" flexDirection="column" selected={this.props.selected}>
        <NanoFlex className="userCardWrapper">
          <NanoFlex className="userInfoWrapper" flexDirection="column" onClick={this.props.onClick}>
            <NanoFlex className="topInfoWrapper" justifyContent="space-between">
              <NanoFlex className="userInfo">
                <NanoFlex className="photoWrapper">
                  <NanoFlex className="imgWrapper">
                    <img src={DefaultUser} alt="Utilizador" />
                  </NanoFlex>
                </NanoFlex>
                <NanoFlex className="infoContainer" flexDirection="column" alignItems="flex-start">
                  <Type.p>
                    <b>{this.props.item.name}</b>
                  </Type.p>
                  <Type.h6>{this.props.item.jobPosition}</Type.h6>
                </NanoFlex>
              </NanoFlex>

              <NanoFlex className="userIcons">
                {this.props.item.isFavorite && (
                  <NanoFlex className="userIconWrapper">
                    <Favorite />
                  </NanoFlex>
                )}
                <NanoFlex className="userIconWrapper">
                  {this.props.item.isConsultant && <Consultant />}
                  {!this.props.item.isConsultant && <Candidate />}
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>

            <NanoFlex className="bottomInfoWrapper">
              <NanoFlex className="leftAside" flexDirection="column" alignItems="flex-start">
                <NanoFlex className="infoLineUser">
                  <NanoFlex className="iconInfoWrapper">
                    <Location />
                  </NanoFlex>
                  <NanoFlex className="infoWrapper" justifyContent="flex-start">
                    <Type.p>{this.props.item.location ?? "-"}</Type.p>
                  </NanoFlex>
                </NanoFlex>
                <NanoFlex className="infoLineUser">
                  <NanoFlex className="iconInfoWrapper">
                    <Briefcase />
                  </NanoFlex>
                  <NanoFlex className="infoWrapper" justifyContent="flex-start">
                    <Type.p>{this.props.item.experience ? `${this.props.item.experience.period} ${this.props.item.experience.periodType.name}` : "-"}</Type.p>
                  </NanoFlex>
                </NanoFlex>
                <NanoFlex className="infoLineUser">
                  <NanoFlex className="iconInfoWrapper">
                    <Rate />
                  </NanoFlex>
                  <NanoFlex className="infoWrapper" justifyContent="flex-start">
                    <Type.p>{this.props.item.salary ? `${this.props.item.salary.min} - ${this.props.item.salary.max} €` : "-"}</Type.p>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
              <NanoFlex className="rightAside">
                <MatchChart matchPercentage={this.props.item.matchPercentage} />
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="userSelectWrapper" onClick={this.props.onSelect}>
            <Check />
          </NanoFlex>
        </NanoFlex>
      </StyledUserCard>
    );
  }
}

export default UserCard;

// --- // Documentation // --- //
/*
    import CardUser from './Cards/UserCard.js';
    
    <CardUser />
    */
