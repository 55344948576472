import React from "react";
import BaseComponent from "../../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../../Global/NanoFlex";
import Type from "../../../Global/Typography.js";

// SERVICES
import candidateService from "../../../../services/CandidateService";

// COMPONENTS
import MatchChart from "../../../Elements/Cards/MatchChart.js";

// COMPONENTS
import Loading from "../../../Elements/Loadings/Loading.js";

// IMAGES
import DefaultUser from "../../../Global/icons/defaultUser.svg";
import { ReactComponent as Candidate } from "../../../Global/icons/candidate.svg";
import { ReactComponent as Consultant } from "../../../Global/icons/collab.svg";
import { ReactComponent as Favorite } from "../../../Global/icons/heart.svg";
import { ReactComponent as Location } from "../../../Global/icons/location.svg";
import { ReactComponent as Briefcase } from "../../../Global/icons/briefcase.svg";
import { ReactComponent as Rate } from "../../../Global/icons/rate.svg";

const StyledCardProfile = styled(NanoFlex)`
  padding-top: 12px;
  .cardProfileWrapper {
    height: calc(100vh - 236px);
    background-color: ${(props) => props.theme.color.greyPalette.white};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    overflow: hidden;
    padding: 16px;

    .topInfoWrapper {
      height: auto;
      border-bottom: 1px solid ${(props) => props.theme.color.greyPalette.lightGrey};
      padding-bottom: 16px;
      .userInfo {
        overflow: hidden;
        padding-right: 8px;
        .photoWrapper {
          width: auto;
          margin-right: 8px;
          .imgWrapper {
            width: 35px;
            height: 35px;
            border-radius: 50px;
            overflow: hidden;
            img {
              width: 100%;
              min-height: 100%;
              object-fit: cover;
            }
          }
        }
        .infoContainer {
          overflow: hidden;
          p {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            b {
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          h6 {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      .userIcons {
        width: auto;
        .userIconWrapper {
          width: auto;
          margin-left: 8px;
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }

    .middleInfoWrapper {
      height: auto;
      border-bottom: 1px solid ${(props) => props.theme.color.greyPalette.lightGrey};
      padding: 16px 0;
      .leftAside {
        .infoLineUser {
          margin-bottom: 4px;
          .iconInfoWrapper {
            width: 34px;
            height: 26px;
            background-color: ${(props) => props.theme.color.greyPalette.lighterGrey};
            padding: 4px;
            margin-right: 8px;
            svg {
              width: auto;
              max-width: 14px;
              height: auto;
              max-height: 14px;
              path {
                fill: ${(props) => props.theme.color.main.blue};
              }
            }
          }
          .infoWrapper {
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .rightAside {
        width: auto;
      }
    }

    .profileInfoWrapper {
      height: calc(100vh - 442px);
      overflow: auto;
      padding-top: 16px;
      .infoContainer {
        height: auto;
        margin-bottom: 16px;
        h6 {
          text-transform: uppercase;
          color: ${(props) => props.theme.color.greyPalette.grey};
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;

class CardProfile extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      candidateId: this.props.candidateId,
      data: null,
    };
  }

  componentDidMount() {
    super.componentDidMount();

    this.getData();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  getData = () => {
    candidateService
      .getCandidateInfoMobile(this.state.candidateId)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        this.setState({ data: data });
      });
  };

  render() {
    return (
      <StyledCardProfile className="cardProfileContainer" flexDirection="column" justifyContent="flex-start">
        {!this.state.data && <Loading />}
        {this.state.data && (
          <NanoFlex className="cardProfileWrapper" flexDirection="column" justifyContent="flex-start">
            <NanoFlex className="topInfoWrapper">
              <NanoFlex className="userInfo">
                <NanoFlex className="photoWrapper">
                  <NanoFlex className="imgWrapper">
                    <img src={DefaultUser} alt="Utilizador" />
                  </NanoFlex>
                </NanoFlex>
                <NanoFlex className="infoContainer" flexDirection="column" alignItems="flex-start">
                  <Type.p>
                    <b>{this.state.data.name}</b>
                  </Type.p>
                  <Type.h6>{this.state.data.jobPosition}</Type.h6>
                </NanoFlex>
              </NanoFlex>

              <NanoFlex className="userIcons">
                {this.state.data.isFavorite && (
                  <NanoFlex className="userIconWrapper">
                    <Favorite />
                  </NanoFlex>
                )}
                <NanoFlex className="userIconWrapper">
                  {this.state.data.isConsultant && <Consultant />}
                  {!this.state.data.isConsultant && <Candidate />}
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>

            <NanoFlex className="middleInfoWrapper">
              <NanoFlex className="leftAside" flexDirection="column" alignItems="flex-start">
                <NanoFlex className="infoLineUser">
                  <NanoFlex className="iconInfoWrapper">
                    <Location />
                  </NanoFlex>
                  <NanoFlex className="infoWrapper" justifyContent="flex-start">
                    <Type.p>{this.state.data.location}</Type.p>
                  </NanoFlex>
                </NanoFlex>
                <NanoFlex className="infoLineUser">
                  <NanoFlex className="iconInfoWrapper">
                    <Briefcase />
                  </NanoFlex>
                  <NanoFlex className="infoWrapper" justifyContent="flex-start">
                    <Type.p>{this.state.data.experience ? `${this.state.data.experience.period} ${this.state.data.experience.periodType.name}` : "-"}</Type.p>
                  </NanoFlex>
                </NanoFlex>
                <NanoFlex className="infoLineUser">
                  <NanoFlex className="iconInfoWrapper">
                    <Rate />
                  </NanoFlex>
                  <NanoFlex className="infoWrapper" justifyContent="flex-start">
                    <Type.p>{this.state.data.salary ? `${this.state.data.salary.min} - ${this.state.data.salary.max} €` : "-"}</Type.p>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
              <NanoFlex className="rightAside">
                <MatchChart matchPercentage={this.props.matchPercentage} />
              </NanoFlex>
            </NanoFlex>

            <NanoFlex className="profileInfoWrapper" flexDirection="column" justifyContent="flex-start">
              {this.state.data.state && (
                <NanoFlex className="infoContainer" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                  <Type.h6>Estado</Type.h6>
                  <Type.p>{this.state.data.state}</Type.p>
                </NanoFlex>
              )}
              {this.state.data.availability && (
                <NanoFlex className="infoContainer" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                  <Type.h6>Disponibilidade</Type.h6>
                  <Type.p>{`${this.state.data.availability.period} ${this.state.data.availability.periodType.name}`}</Type.p>
                </NanoFlex>
              )}
              {this.state.data.profiles?.length > 0 && (
                <NanoFlex className="infoContainer" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                  <Type.h6>Perfil</Type.h6>
                  <Type.p>{this.state.data.profiles.join(", ")}</Type.p>
                </NanoFlex>
              )}

              {this.state.data.areas?.length > 0 && (
                <NanoFlex className="infoContainer" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                  <Type.h6>Técnica</Type.h6>
                  <Type.p>{this.state.data.areas.join(", ")}</Type.p>
                </NanoFlex>
              )}

              {this.state.data.skills?.length > 0 && (
                <NanoFlex className="infoContainer" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                  <Type.h6>Especialização</Type.h6>
                  <Type.p>{this.state.data.skills.join(", ")}</Type.p>
                </NanoFlex>
              )}

              {this.state.data.languages?.length > 0 && (
                <NanoFlex className="infoContainer" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                  <Type.h6>Línguas</Type.h6>
                  <Type.p>{this.state.data.languages.map((l) => `${l.language.name} ${l.level.name}`).join(",")}</Type.p>
                </NanoFlex>
              )}

              {this.state.data.observations && (
                <NanoFlex className="infoContainer" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                  <Type.h6>Observações</Type.h6>
                  <Type.p>{this.state.data.observations}</Type.p>
                </NanoFlex>
              )}
            </NanoFlex>
          </NanoFlex>
        )}
      </StyledCardProfile>
    );
  }
}

export default CardProfile;
