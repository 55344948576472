import React, { Component } from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";

// COMPONENTS
import ButtonIcon from "../../Elements/Buttons/ButtonIcon.js";
import SearchBar from "../../Elements/Forms/SearchBar.js";

// IMAGES
import Filter from "../../Global/icons/filter.svg";
import Candidate from "../../Global/icons/candidateWhite.svg";
import Consultant from "../../Global/icons/collabWhite.svg";

const StyledSearchFilter = styled(NanoFlex)`
  height: auto;
  .navBarWrapper {
    padding: 12px 24px 16px 24px;
    .logoWrapper {
      svg {
        height: 42px;
        width: auto;
      }
    }
    .iconButtonContainer {
      margin-top: 8px;

      .asideBtnsWrapper {
        .btnWrapper {
          width: auto;
          margin-right: 8px;

          &:last-child {
            margin-right: 0;
          }
        }

        .candidateBtn {
          .buttonIcon {
            .iconWrapper {
              img {
                margin-left: 4px;
              }
            }
          }
        }

        .filterBtn {
          .buttonIcon {
            .iconWrapper {
              img {
                margin-left: 1px;
                margin-top: 3px;
              }
            }
          }
        }
      }
    }
  }
`;

class SearchFilter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            term: this.props.term,
            searchType: this.props.searchType
        };

        this.requestId = 0;
    }

    componentDidUpdate(prevProps, prevState) {
        //Search
        if (JSON.stringify(prevState.term) !== JSON.stringify(this.state.term)) {
            this.requestId++;
            var requestId = this.requestId;
            setTimeout(() => {
                if (requestId === this.requestId) {
                    this.props.onTermChange(this.state.term);
                }
            }, 500);
        }

        if (prevState.searchType !== this.state.searchType) {
            this.props.onSearchTypeChange(this.state.searchType);
        }

        if (this.props.term !== prevProps.term) {
            this.setState({ term: this.props.term });
        }

        if (this.props.searchType !== prevProps.searchType) {
            this.setState({ searchType: this.props.searchType });
        }
    }

    onSearchType = (type) => {
        this.setState({ searchType: type !== this.state.searchType ? type : null })
    }

    render() {
        return (
            <StyledSearchFilter className="navBarContainer">
                <NanoFlex className="navBarWrapper" flexDirection="column" justifyContent="space-between">
                    <NanoFlex className="searchWrapper">
                        <SearchBar placeholder="Pesquisar..." value={this.state.term} onChangeAction={(value) => this.setState({ term: value })} />
                    </NanoFlex>
                    <NanoFlex className="iconButtonContainer" justifyContent="space-between">
                        <NanoFlex className="asideBtnsWrapper" justifyContent="flex-start">
                            <NanoFlex className="btnWrapper candidateBtn">
                                <ButtonIcon unselected={this.state.searchType === 1} blue={this.state.searchType !== 1} small icon={Candidate} clickAction={() => this.onSearchType(1)} />
                            </NanoFlex>
                            <NanoFlex className="btnWrapper consultantBtn">
                                <ButtonIcon unselected={this.state.searchType === 0} blue={this.state.searchType !== 0} small icon={Consultant} clickAction={() => this.onSearchType(0)} />
                            </NanoFlex>
                        </NanoFlex>

                        <NanoFlex className="asideBtnsWrapper" justifyContent="flex-end">
                            <NanoFlex className="btnWrapper filterBtn">
                                <ButtonIcon orange={this.props.hasFilters} darkBlue={!this.props.hasFilters} small icon={Filter} clickAction={this.props.openFilters} />
                            </NanoFlex>
                        </NanoFlex>
                    </NanoFlex>
                </NanoFlex>
            </StyledSearchFilter>
        );
    }
}

export default SearchFilter;
