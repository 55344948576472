import { BaseService } from "./BaseService";

export class AccountService extends BaseService {
  authenticate(username, password) {
    return this.request(`Account/Authenticate?Username=${encodeURIComponent(username)}&Password=${encodeURIComponent(password)}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  forgotPassword(email) {
    return this.request(`Account/ForgotPassword?Email=${encodeURIComponent(email)}&From=1`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  isTokenValid(token) {
    return this.request(`Account/IsTokenValid?Token=${encodeURIComponent(token)}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  changePassword(token, password) {
    return this.request(`Account/ChangePassword`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "PUT",
      body: JSON.stringify({
        token,
        password,
      }),
    });
  }
}

const accountService = new AccountService();

export default accountService;
