import React, { Component } from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";

// COMPONENTS
import ButtonBackList from "../../Elements/Buttons/ButtonBackList.js";
import ButtonCTA from "../../Elements/Buttons/ButtonCTA.js";

// IMAGES
import { ReactComponent as Check } from "../../Global/icons/check.svg";
import { ReactComponent as Alert } from "../../Global/icons/alert.svg";

const StyledSharePopUp = styled(NanoFlex)`
  height: auto;
  background-color: ${(props) => props.theme.color.greyPalette.white};
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 32px 32px 0 0;
  -webkit-animation: scale-up-ver-bottom 0.4s ease-in-out;
      animation: scale-up-ver-bottom 0.4s ease-in-out;
  overflow: hidden;
  .shareWrapper {
    .messageContainer {
      height: 50vh;
      padding: 24px;
      .messageWrapper {
        .iconMessageWrapper {
          height: auto;
          margin-bottom: 24px;
          svg {
            height: 80px;
            width: auto;
            path {
              fill: ${(props) => props.theme.color.main.blue};
            }
          }
        }
        .messageContainer {
          height: auto;
          text-align: center;
          padding: 24px;
          h4 {
            margin-bottom: 16px;
            text-transform: uppercase;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        .btnWrapper {
          margin-top: 24px;
          height: auto;
        }
      }
    }

    .error {
      display: none;
      .messageWrapper {
        .iconMessageWrapper {
          svg {
            path {
              fill: ${(props) => props.theme.color.main.red};
            }
          }
        }
      }
    }

    .bottomBarWrapper {
      height: auto;
      border-top: 1px solid ${(props) => props.theme.color.greyPalette.lightGrey};
      padding: 24px;

      .asideBottomBar {
        width: auto;
        .btnWrapper {
          width: auto;
          margin-right: 16px;
          .backListContainer {
            .backListWrapper {
              padding: 0;
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
`;

class SharePopUp extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledSharePopUp className="shareContainer" flexDirection="column" justifyContent="flex-start">
        <NanoFlex className="shareWrapper" flexDirection="column" justifyContent="flex-start">
          {/* SUCCESS MESSAGE*/}
          {/* Está display none no CSS */}
          <NanoFlex className="messageContainer">
            <NanoFlex className="messageWrapper" flexDirection="column">
              <NanoFlex className="iconMessageWrapper">
                <Check />
              </NanoFlex>
              <NanoFlex className="messageContainer" flexDirection="column">
                <Type.h4>
                  <b>A sua partilha foi feita com sucesso!</b>
                </Type.h4>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
          {/* END SUCCESS MESSAGE*/}

          {/* ERROR MESSAGE*/}
          {/* Está display none no CSS */}
          <NanoFlex className="messageContainer error">
            <NanoFlex className="messageWrapper" flexDirection="column">
              <NanoFlex className="iconMessageWrapper">
                <Alert />
              </NanoFlex>
              <NanoFlex className="messageContainer" flexDirection="column">
                <Type.h4>
                  <b>Ocorreu um erro na sua partilha!</b>
                </Type.h4>
              </NanoFlex>
              <NanoFlex className="btnWrapper">
                <ButtonCTA label="Tentar Novamente" />
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
          {/* END ERROR MESSAGE*/}

          <NanoFlex className="bottomBarWrapper" justifyContent="flex-start" alignItems="flex-start">
            <NanoFlex className="asideBottomBar" justifyContent="flex-start" alignItems="flex-start">
              <NanoFlex className="btnWrapper">
                <ButtonBackList />
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledSharePopUp>
    );
  }
}

export default SharePopUp;
