import React, { Component } from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";

// IMAGES
import DefaultUser from "../../Global/icons/defaultUser.svg";
import { ReactComponent as Close } from "../../Global/icons/close.svg";

const StyledUserCard = styled(NanoFlex)`
  height: auto;
  margin-right: 16px;
  .userCardWrapper {
    width: 200px;
    background-color: ${(props) => props.theme.color.greyPalette.white};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    position: relative;
    .userInfoWrapper {
      padding: 8px 16px 8px 8px;
      .topInfoWrapper {
        .userInfo {
          .photoWrapper {
            width: auto;
            margin-right: 8px;
            .imgWrapper {
              width: 35px;
              height: 35px;
              border-radius: 50px;
              overflow: hidden;
              img {
                width: 100%;
                min-height: 100%;
                object-fit: cover;
              }
            }
          }
          .infoContainer {
            overflow: hidden;
            p {
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            h6 {
              width: 90%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
    .deleteUserWrapper {
      width: auto;
      height: auto;
      position: absolute;
      bottom: -8px;
      right: -8px;
      .deleteIcon {
        background-color: ${(props) => props.theme.color.greyPalette.lighterGrey};
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        height: 32px;
        width: 32px;
        border-radius: 40px;
        svg {
          width: 20px;
          margin-top: 3px;
          path {
            fill: ${(props) => props.theme.color.main.font};
          }
        }
      }
    }
  }
  &:last-child {
    margin-right: 0;
  }
`;

class UserCard extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledUserCard className="cardContainer" flexDirection="column" alignItems="flex-start">
        <NanoFlex className="userCardWrapper">
          <NanoFlex className="userInfoWrapper" flexDirection="column">
            <NanoFlex className="topInfoWrapper">
              <NanoFlex className="userInfo">
                <NanoFlex className="photoWrapper">
                  <NanoFlex className="imgWrapper">
                    <img src={DefaultUser} alt="Utilizador" />
                  </NanoFlex>
                </NanoFlex>
                <NanoFlex className="infoContainer" flexDirection="column" alignItems="flex-start">
                  <Type.p>
                    <b>Pedro Oliveira</b>
                  </Type.p>
                  <Type.h6>Front-end Dev</Type.h6>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="deleteUserWrapper">
            <NanoFlex className="deleteIcon">
              <Close />
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledUserCard>
    );
  }
}

export default UserCard;

// --- // Documentation // --- //
/*
    import CardUser from './Cards/UserCard.js';
    
    <CardUser />
    */
