import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled, { css } from "styled-components";
import NanoFlex from "../../Global/NanoFlex";

// COMPONENTS
/* Filters */
import Filters from "./Filters/Filters.js";
/* Results */
import ReadyToMatch from "./Results/ReadyToMatch.js";
import NoResults from "./Results/NoResults.js";
import ResultsList from "./Results/ResultsList.js";
/* Profile */
import Profile from "./Profile/Profile.js";
/* Search */
import SearchFilter from "./SearchFilter.js";

const StyledSearch = styled(NanoFlex)`
  position: relative;
  .searchWrapper {
    height: auto;

    .filtersView {
        height: auto;
      display: ${(props) => (props.openFilters ? "flex" : "none")};
      position: absolute;
      z-index: 1;
    }

    .profileView {
      display: ${(props) => (props.openProfile ? "flex" : "none")};
      position: absolute;
      z-index: 1;
    }
  }

  ${(props) =>
        (props.openFilters || props.openProfile) &&
        css`
      .searchResultContainer {
        visibility: hidden;
      }
    `}
`;

export class Search extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            openFilters: false,
            candidateId: null,
            matchPercentage: null,

            term: "",
            searchType: null,
            filter: null,
            count: null,
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    render() {
        let canSearch = this.state.term?.length > 3 || this.state.filter !== null;

        return (
            <StyledSearch className="searchContainer" flexDirection="column" justifyContent="flex-start" openFilters={this.state.openFilters} openProfile={this.state.candidateId}>
                <NanoFlex className="searchWrapper" flexDirection="column" justifyContent="space-between">
                    <NanoFlex className="searchResultContainer" flexDirection="column" justifyContent="space-between">
                        <NanoFlex className="searchFilter">
                            <SearchFilter
                                openFilters={() => {
                                    this.setState({ openFilters: true });
                                }}
                                term={this.state.term}
                                onTermChange={(term) => this.setState({ term: term })}
                                searchType={this.state.searchType}
                                onSearchTypeChange={(searchType) => this.setState({ searchType: searchType })}
                                hasFilters={this.state.filter !== null}
                            />
                        </NanoFlex>
                        {/* READY TO MAKE A MATCH */}
                        {!canSearch && (
                            <NanoFlex className="readyToMatch">
                                <ReadyToMatch />
                            </NanoFlex>
                        )}
                        {/* END READY TO MAKE A MATCH */}

                        {/* RESULT LIST */}
                        {canSearch && (
                            <NanoFlex className="resultsList">
                                <ResultsList
                                    filter={{ ...this.state.filter, ...{ freeText: this.state.term, searchType: this.state.searchType } }}
                                    openProfile={(id, matchPercentage) => {
                                        this.setState({ candidateId: id, matchPercentage: matchPercentage });
                                    }}
                                />
                            </NanoFlex>
                        )}
                        {/* END RESULT LIST */}
                    </NanoFlex>

                    {/* FILTERS */}
                    <NanoFlex className="filtersView">
                        <Filters
                            term={this.state.term}
                            closeFilters={() => {
                                this.setState({ openFilters: false });
                            }}
                            onFiltersChange={(filters, term) => {
                                this.setState({ filter: filters, term: term, openFilters: false });
                            }}
                        />
                    </NanoFlex>

                    {/* PROFILE */}
                    {this.state.candidateId && (
                        <NanoFlex className="profileView">
                            <Profile
                                candidateId={this.state.candidateId}
                                matchPercentage={this.state.matchPercentage}
                                backToList={() => {
                                    this.setState({ candidateId: null });
                                }}
                            />
                        </NanoFlex>
                    )}

                    {/* FILTERS */}
                </NanoFlex>
            </StyledSearch>
        );
    }
}
