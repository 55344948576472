import React from "react";
import styled from "styled-components";
import BaseComponent from "../../BaseComponent.js";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

// COMPONENTS
import ButtonCTA from "../../Elements/Buttons/ButtonCTA.js";
import ButtonIcon from "../../Elements/Buttons/ButtonIcon.js";
import ButtonBackList from "../../Elements/Buttons/ButtonBackList.js";
import HexagonButton from "../../Elements/Buttons/HexagonButton.js";
import Input from "../../Elements/Forms/Input.js";
import Textarea from "../../Elements/Forms/Textarea.js";
import SearchBar from "../../Elements/Forms/SearchBar.js";
import MultipleSelectionDropdown from "../../Elements/Forms/MultipleSelectionDropdown.js";
import UserCard from "../../Elements/Cards/UserCard.js";
import NavBar from "../Shared/NavBar.js";
import Loading from "../../Elements/Loadings/Loading.js";

// IMAGES
import { ReactComponent as MlogLogo } from "../../Global/images/mlogLogoSignature.svg";
import Download from "../../Global/icons/download.svg";
import Email from "../../Global/icons/email.svg";
import Filter from "../../Global/icons/filter.svg";
import Logout from "../../Global/icons/logout.svg";
import Phone from "../../Global/icons/phone.svg";
import Share from "../../Global/icons/share.svg";
import MatchMaker from "../../Global/icons/matchmaker.svg";
import Timesheets from "../../Global/icons/timesheets.svg";
import Calendar from "../../Global/icons/calendar.svg";

const StyledGuidelines = styled(NanoFlex)`
  background-color: ${(props) => props.theme.color.greyPalette.lighterGrey};
  padding-top: 30px;
  .guidelineBlock {
    height: auto;
    padding: 62px;
  }
`;

export class Guidelines extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledGuidelines flexDirection={"column"} justifyContent={"flex-start"}>
        {/* MLOG GUIDELINES */}
        <NanoFlex className="guidelineBlock" flexDirection={"column"}>
          <MlogLogo />
          <Type.h1>
            <b>GUIDELINES</b>
          </Type.h1>
        </NanoFlex>

        {/* TYPOGRAPHY */}
        <NanoFlex className="guidelineBlock" flexDirection={"column"} alignItems={"flex-start"}>
          <Type.h1>
            <b>H1</b> Lorem ipsum <b>dolor sit amet</b>
          </Type.h1>
          <Type.h2>
            <b>H2</b> Lorem ipsum <b>dolor sit amet</b>
          </Type.h2>
          <Type.h3>
            <b>H3</b> Lorem ipsum <b>dolor sit amet</b>
          </Type.h3>
          <Type.h4>
            <b>H4</b> Lorem ipsum <b>dolor sit amet</b>
          </Type.h4>
          <Type.h5>
            <b>H5</b> Lorem ipsum <b>dolor sit amet</b>
          </Type.h5>
          <Type.h6>
            <b>H6</b> Lorem ipsum <b>dolor sit amet</b>
          </Type.h6>
          <Type.p>
            <b>P</b> Lorem ipsum <b>dolor sit amet</b>
          </Type.p>
        </NanoFlex>

        {/* BUTTONS CTA */}
        <NanoFlex className="guidelineBlock" flexDirection={"column"} alignItems={"flex-start"}>
          <ButtonCTA label="Tentar Novamente" />
          <ButtonCTA darkBlue label="Iniciar Sessão" />

          <ButtonCTA smallCta label="Enviar" />
          <ButtonCTA orange smallCta label="Filtrar" />
        </NanoFlex>

        {/* BUTTONS ICONS */}
        <NanoFlex className="guidelineBlock" alignItems={"flex-start"}>
          <ButtonIcon icon={Email} />
          <ButtonIcon icon={Download} />
          <ButtonIcon icon={Phone} />
          <ButtonIcon blue icon={Share} />
        </NanoFlex>
        <NanoFlex className="guidelineBlock" alignItems={"flex-start"}>
          <ButtonIcon darkBlue small icon={Filter} />
          <ButtonIcon orange small icon={Filter} />
          <ButtonIcon white small icon={Logout} />
        </NanoFlex>

        {/* BUTTON BACK LIST */}
        <NanoFlex className="guidelineBlock" alignItems={"flex-start"}>
          <ButtonBackList />
        </NanoFlex>

        {/* HEXAGON BUTTONS */}
        <NanoFlex className="guidelineBlock" flexDirection={"column"} alignItems={"flex-start"}>
          <HexagonButton icon={MatchMaker} />
          <HexagonButton disabled icon={Timesheets} />
          <HexagonButton disabled icon={Calendar} />
        </NanoFlex>

        {/* INPUT */}
        <NanoFlex className="guidelineBlock" flexDirection={"column"} alignItems={"flex-start"}>
          <Input id={0} title="Username" placeholder="Username" error={false} onChangeAction={(currentValue, id, title, type) => console.log("onChangeAction", currentValue, id, title, type)} />
          <Input
            id={1}
            type={this.state.showPassword ? "text" : "password"}
            title="Password"
            placeholder=""
            eye={true}
            showPassword={this.state.showPassword}
            error={false}
            onClickEyeToggle={() => this.setState({ showPassword: !this.state.showPassword })}
            onChangeAction={(currentValue, id, title, type) => console.log("onChangeAction", currentValue, id, title, type)}
          />
          <Input id={2} title="Error" placeholder="Error" error={true} onChangeAction={(currentValue, id, title, type) => console.log("onChangeAction", currentValue, id, title, type)} />
        </NanoFlex>

        {/* TEXTAREA */}
        <NanoFlex className="guidelineBlock" flexDirection={"column"} alignItems={"flex-start"}>
          <Textarea title="Mensagem" placeholder="Insira uma mensagem..." />
        </NanoFlex>

        {/* SEARCHBAR */}
        <NanoFlex className="guidelineBlock" flexDirection={"column"} alignItems={"flex-start"}>
          <SearchBar placeholder="Pesquisar..." />
        </NanoFlex>

        {/* MultipleSelectionDropdown */}
        <NanoFlex className="guidelineBlock" flexDirection={"column"} alignItems={"flex-start"}>
          <MultipleSelectionDropdown
            options={[
              { id: 1, name: "teste" },
              { id: 2, name: "teste2" },
            ]}
          />
        </NanoFlex>

        {/* USER CARDS */}
        <NanoFlex className="guidelineBlock" flexDirection={"column"} alignItems={"flex-start"} width="500px">
          <UserCard item={{}} />
        </NanoFlex>

        {/* NAVBAR */}
        <NanoFlex className="guidelineBlock" flexDirection={"column"} alignItems={"flex-start"} width="500px">
          <NavBar />
        </NanoFlex>

        {/* LOADING */}
        <NanoFlex className="guidelineBlock" flexDirection={"column"} width="500px">
          <Loading />
        </NanoFlex>
      </StyledGuidelines>
    );
  }
}
