import React, { Component } from "react";
import { Link } from "react-router-dom";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";

// COMPONENTS
import HexagonButton from "../../Elements/Buttons/HexagonButton.js";

// IMAGES
import { ReactComponent as MlogLogo } from "../../Global/images/mlogLogoSignature.svg";
import { ReactComponent as PoweredBy } from "../../Global/icons/poweredBy.svg";
import MatchMaker from "../../Global/icons/matchmaker.svg";
import Timesheets from "../../Global/icons/timesheets.svg";
import Calendar from "../../Global/icons/calendar.svg";

const StyledHome = styled(NanoFlex)`
  background: linear-gradient(0deg, #0b679f 0%, #ffffff 100%);
  height: 100vh;
  width: 100vw;

  .logoWrapper {
    height: 20vh;
    padding-top: 5vh;
    svg {
      width: auto;
      height: 100%;
    }
  }

  .loginContainer {
    height: calc(100vh - 25vh);
    position: relative;

    .bckWrapper {
      position: fixed;
      height: 100%;
      width: 100%;

      .bckObject {
        width: 100%;
        height: 100%;
        transform: skew(0deg, -6deg);
        background-color: ${(props) => props.theme.color.main.blue};
        border-radius: 55px;
        position: absolute;
        top: 20px;
      }
      .bckObject2 {
        width: 100%;
        height: 100%;
        transform: skew(0deg, -6deg) rotate(-6deg);
        background-color: ${(props) => props.theme.color.main.blue};
        opacity: 0.4;
        border-radius: 55px;
      }
    }

    .navBtnsWrapper {
      padding: 16vh 40px 20px 40px;
      height: auto;
      .itemsLine {
        margin-top: -36px;
        &:first-line {
          margin-top: 0;
        }
      }
    }

    .poweredWrapper {
      position: relative;
      z-index: 2;
      height: auto;
      padding: 20px;
      .poweredBy {
        width: 30vw;
        svg {
          width: auto;
          height: auto;
        }
      }
    }
  }
`;

export class Home extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledHome flexDirection="column" justifyContent="space-between">
        <NanoFlex className="logoWrapper">
          <MlogLogo />
        </NanoFlex>
        <NanoFlex className="loginContainer" flexDirection="column" justifyContent="space-between">
          <NanoFlex className="bckWrapper">
            <NanoFlex className="bckObject"></NanoFlex>
            <NanoFlex className="bckObject2"></NanoFlex>
          </NanoFlex>

          <NanoFlex className="navBtnsWrapper" flexDirection="column">
            <NanoFlex className="itemsLine">
              <Link to="/search">
                <HexagonButton icon={MatchMaker} />
              </Link>
            </NanoFlex>
            <NanoFlex className="itemsLine">
              <HexagonButton disabled icon={Timesheets} />
              <HexagonButton disabled icon={Calendar} />
            </NanoFlex>
          </NanoFlex>

          <NanoFlex className="poweredWrapper" justifyContent="flex-end" alignItems="flex-end">
            <NanoFlex className="poweredBy" justifyContent="flex-end" alignItems="flex-end">
              <PoweredBy />
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledHome>
    );
  }
}
