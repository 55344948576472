import React from "react";
import BaseComponent from "../../../BaseComponent";
import styled from "styled-components";
import NanoFlex from "../../../Global/NanoFlex";
import Type from "../../../Global/Typography.js";

// COMPONENTS

// IMAGES
import NoMatchs from "../../../Global/images/noMatchs.svg";

const StyledNoResults = styled(NanoFlex)`
  height: calc(100vh - 156px);
  background-image: url(${NoMatchs});
  background-repeat: no-repeat, repeat;
  background-size: cover;
  background-position: unset;
  .noResultsWrapper {
    h1 {
      font-size: 46px;
      line-height: 48px;
      color: ${(props) => props.theme.color.greyPalette.grey};
    }
  }
`;

class NoResults extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledNoResults className="noResultsContainer" flexDirection="column" justifyContent="flex-start">
        <NanoFlex className="noResultsWrapper" flexDirection="column">
          <Type.h1>
            <b>ohh!</b>
          </Type.h1>
          <Type.h1>
            <b>no matchs!</b>
          </Type.h1>
        </NanoFlex>
      </StyledNoResults>
    );
  }
}

export default NoResults;
