import packageJson from "../../../package.json";

export const elementMatches = (el, selector) => {
  return (el.matches || el.matchesSelector || el.msMatchesSelector || el.mozMatchesSelector || el.webkitMatchesSelector || el.oMatchesSelector).call(el, selector);
};

export const elementMatchesAny = (el, selectorArray) => {
  for (let idx = 0; idx < selectorArray.length; idx++) {
    let v = selectorArray[idx];
    if (elementMatches(el, v) || el.parentElement.closest(v)) {
      return true;
    }
  }
  return false;
};

export const validateEmailFormat = function (val) {
  var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(val.toLowerCase());
};

export const downloadBlob = function (blob, filename) {
  var url = window.URL.createObjectURL(blob);
  var a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const validateVersion = () => {
  let version = localStorage.getItem("version");
  if (!version) {
    localStorage.setItem("version", packageJson.version);
  } else if (version != packageJson.version) {
    if ("caches" in window) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach((name) => {
          caches.delete(name);
        });
      });

      window.location.href = "/login";
    }
    localStorage.setItem("version", packageJson.version);
  }
};
