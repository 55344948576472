import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

// IMAGES
import { ReactComponent as Hexagon } from "../../Global/icons/hexagon.svg";

const HexagonButtonStyled = styled(NanoFlex)`
  height: auto;
  width: auto;
  position: relative;
  cursor: pointer;
  pointer-events: ${(props) => (props.disabled ? "none" : "")};
  opacity: ${(props) => (props.disabled ? ".5" : "1")};
  .hexagonShape {
    width: 160px;
    height: auto;
    svg {
      path {
        fill: ${(props) => props.theme.color.main.darkBlue};
      }
    }
  }
  .iconWrapper {
    position: absolute;
    width: auto;
    height: auto;
    img {
      width: 100px;
      height: auto;
      max-height: 86px;
    }
  }
`;

class HexagonButton extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <HexagonButtonStyled className="hexagonButtonWrapper" disabled={this.props.disabled}>
        <NanoFlex className="hexagonShape">
          <Hexagon />
        </NanoFlex>
        <NanoFlex className="iconWrapper">
          <img src={this.props.icon} alt="icon" />
        </NanoFlex>
      </HexagonButtonStyled>
    );
  }
}

// Set default props
HexagonButton.defaultProps = {
  icon: "",
};

export default HexagonButton;
