import React, { Component } from "react";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

const StyledMatchChart = styled(NanoFlex)`
  height: auto;
  width: auto;
  .donutChart {
    position: relative;
    .chart {
      .circularChart {
        width: 90px;
        height: 90px;
        .circleBackground {
          fill: none;
          stroke: ${(props) => props.theme.color.greyPalette.lighterGrey};
          stroke-width: 6;
        }
        .circle {
          fill: none;
          stroke-width: 6;
          stroke: ${(props) => (props.matchPercentage >= 75 ? props.theme.color.main.green : props.matchPercentage >= 50 ? props.theme.color.main.yellow : props.matchPercentage >= 25 ? props.theme.color.main.orange : props.theme.color.main.red)};
          animation: progress 1s ease-out forwards;
        }
      }
    }
    .matchPercent {
      position: absolute;
      width: auto;
      height: auto;
    }
  }
`;

class MatchChart extends Component {
  render() {
    return (
      <StyledMatchChart matchPercentage={this.props.matchPercentage}>
        <NanoFlex className="donutChart">
          <NanoFlex className="chart">
            <svg viewBox="0 0 36 36" className="circularChart">
              <path className="circleBackground" d="M 18 3.5 a 12.9155 12.9155 0 0 1 0 28.831 a 12.9155 12.9155 0 0 1 0 -28.831"></path>

              <path className="circle" strokeDasharray={`${(((this.props.matchPercentage === 100 ? 200 : (this.props.matchPercentage ?? 0))*90)/100)}, 100`} d="M 18 3.5 a 12.9155 12.9155 0 0 1 0 28.831 a 12.9155 12.9155 0 0 1 0 -28.831"></path>
            </svg>
          </NanoFlex>
          <NanoFlex className="matchPercent">
            <Type.h4>
              <b>{this.props.matchPercentage ?? 0}%</b>
            </Type.h4>
          </NanoFlex>
        </NanoFlex>
      </StyledMatchChart>
    );
  }
}
export default MatchChart;
