import React from "react";
import BaseComponent from "../../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../../Global/NanoFlex";
import Type from "../../../Global/Typography.js";

// SERVICES
import candidateService from "../../../../services/CandidateService";

// COMPONENTS
import Input from "../../../Elements/Forms/Input.js";
import Textarea from "../../../Elements/Forms/Textarea.js";
import ButtonBack from "../../../Elements/Buttons/ButtonBack.js";
import ButtonCTA from "../../../Elements/Buttons/ButtonCTA.js";
import Loading from "../../../Elements/Loadings/Loading.js";

// IMAGES
import { ReactComponent as Email } from "../../../Global/icons/email.svg";
import { ReactComponent as Check } from "../../../Global/icons/check.svg";
import { ReactComponent as Alert } from "../../../Global/icons/alert.svg";

const StyledEmailPopUp = styled(NanoFlex)`
  height: auto;
  /* padding: 16px 24px; */
  background-color: ${(props) => props.theme.color.greyPalette.white};
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 32px 32px 0 0;
  -webkit-animation: scale-up-ver-bottom 0.4s ease-in-out;
      animation: scale-up-ver-bottom 0.4s ease-in-out;
  overflow: hidden;
  .emailWrapper {
    .topBar {
      background-color: ${(props) => props.theme.color.main.blue};
      padding: 16px;
      svg {
        width: 24px;
        height: auto;
        path {
          fill: ${(props) => props.theme.color.greyPalette.white};
        }
      }
    }
    .formContainer {
      padding: 24px;
      .formWrapper {
        .TextAreaContainer {
          .TextAreaWrapper {
            textarea {
              height: 50vh;
            }
          }
        }
      }
    }

    .messageContainer {
      height: calc(50vh + 164px);
      padding: 24px;
      .messageWrapper {
        .iconMessageWrapper {
          height: auto;
          margin-bottom: 24px;
          svg {
            height: 80px;
            width: auto;
            path {
              fill: ${(props) => props.theme.color.main.blue};
            }
          }
        }
        .messageContainer {
          height: auto;
          text-align: center;
          padding: 24px;
          h4 {
            margin-bottom: 16px;
            text-transform: uppercase;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        .btnWrapper {
          margin-top: 24px;
          height: auto;
        }
      }
    }

    .error {
      .messageWrapper {
        .iconMessageWrapper {
          svg {
            path {
              fill: ${(props) => props.theme.color.main.red};
            }
          }
        }
      }
    }

    .bottomBarWrapper {
      height: auto;
      border-top: 1px solid ${(props) => props.theme.color.greyPalette.lightGrey};
      padding: 16px 24px 16px 24px;

      .asideBottomBar {
        width: auto;
        .btnWrapper {
          width: auto;
          margin-right: 16px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
`;

class EmailPopUp extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      sendEmailSuccess: null,
      sendingEmail: false
    };

    this.subject = '';
    this.body = '';
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  onSendEmail = () => {
    var candidatesIds = [];

    if (this.props.selectedIds && this.props.selectedIds?.length > 0){
      candidatesIds = this.props.selectedIds;
    }
    else if (this.props.candidateId > 0){
      candidatesIds.push(this.props.candidateId);
    }

    if (candidatesIds.length > 0){
      this.setState({ sendingEmail: true }, () => {
      var model = {
        subject: this.subject,
        body: this.body,
        candidatesIds: candidatesIds
      };

      candidateService
        .sendEmailToCandidate(model)
        .then((response) => {
          this.setState({ sendEmailSuccess: response.ok === true, sendingEmail: false });
        });
      });
    }
  }

  render() {
    return (
      <StyledEmailPopUp className="emailContainer" flexDirection="column" justifyContent="flex-start">
        <NanoFlex className="emailWrapper" flexDirection="column" justifyContent="flex-start">
          <NanoFlex className="topBar">
            <NanoFlex className="iconTopBar">
              <Email />
            </NanoFlex>
          </NanoFlex>
          {this.state.sendingEmail && 
          <NanoFlex className="messageContainer">
            <Loading />
          </NanoFlex>}
          {/* FORM */}
          {!this.state.sendingEmail && this.state.sendEmailSuccess === null && 
          <NanoFlex className="formContainer" flexDirection="column">
            <NanoFlex className="formWrapper">
              <Input id={0} title="Assunto" placeholder="Insira o assunto..." error={false} onChangeAction={(value) => this.subject = value} />
            </NanoFlex>
            <NanoFlex className="formWrapper">
              <Textarea title="Mensagem" placeholder="Insira uma mensagem..." error={false} onChangeAction={(value) => this.body = value} />
            </NanoFlex>
          </NanoFlex>}
          {/* END FORM */}

          {/* SUCCESS MESSAGE*/}
          {!this.state.sendingEmail && this.state.sendEmailSuccess === true &&
          <NanoFlex className="messageContainer">
            <NanoFlex className="messageWrapper" flexDirection="column">
              <NanoFlex className="iconMessageWrapper">
                <Check />
              </NanoFlex>
              <NanoFlex className="messageContainer" flexDirection="column">
                <Type.h4>
                  <b>O seu email foi enviado com sucesso!</b>
                </Type.h4>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>}
          {/* END SUCCESS MESSAGE*/}

          {/* ERROR MESSAGE*/}
          {!this.state.sendingEmail && this.state.sendEmailSuccess === false &&
          <NanoFlex className="messageContainer error">
            <NanoFlex className="messageWrapper" flexDirection="column">
              <NanoFlex className="iconMessageWrapper">
                <Alert />
              </NanoFlex>
              <NanoFlex className="messageContainer" flexDirection="column">
                <Type.h4>
                  <b>Ocorreu um erro no envio do email!</b>
                </Type.h4>
              </NanoFlex>
              <NanoFlex className="btnWrapper">
                <ButtonCTA label="Tentar Novamente" />
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>}
          {/* END ERROR MESSAGE*/}

          <NanoFlex className="bottomBarWrapper" justifyContent="space-between">
            <NanoFlex className="asideBottomBar">
              <NanoFlex className="btnWrapper" onClick={this.props.closeEmail}>
                <ButtonBack />
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="asideBottomBar">
              {!this.state.sendingEmail && this.state.sendEmailSuccess === null &&
              <NanoFlex className="btnWrapper"
                onClick={() =>{
                  this.onSendEmail()
                }}>
                <ButtonCTA smallCta label="Enviar" />
              </NanoFlex>}
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledEmailPopUp>
    );
  }
}

export default EmailPopUp;
