import React from "react";
import styled from "styled-components";

import NanoFlex from "../../Global/NanoFlex.js";

import { ReactComponent as ArrowRight } from "../../Global/icons/arrowRight.svg";
import { ReactComponent as ArrowUp } from "../../Global/icons/arrowUp.svg";

const multiple = 8;

const StyledTreeNavigator = styled.div`
  cursor: pointer;
  width: 100%;
  .treeWrapper {
    height: auto;
    .arrowWrapper {
      height: ${multiple * 4 + "px"};
      width: 25px;
      max-width: 25px;
      padding: ${multiple / 2 + "px"} ${multiple + "px"};
      background-color: ${(props) => props.theme.color.greyPalette.lighterGrey};
      svg {
        width: 10px;
        height: 10px;
        path {
          fill: ${(props) => (props.indicator ? props.theme.color.main.orange : props.theme.color.greyPalette.grey)};
        }
      }
    }
  }
  .treeOpenWrapper {
    width: calc(100% - 42px);
    margin-left: 42px;
    div {
      width: 100%;
    }
  }
`;

class TreeNavigator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: this.props.isOpen ? this.props.isOpen : false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isOpen !== this.props.isOpen && this.state.isOpen !== this.props.isOpen) {
      this.setState({ isOpen: this.props.isOpen ? this.props.isOpen : false });
    }
  }

  toggleOpen = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    return (
      <StyledTreeNavigator indicator={this.props.indicator}>
        <NanoFlex className="treeWrapper" justifyContent="flex-start">
          <NanoFlex className="arrowWrapper" onClick={this.toggleOpen}>
            {this.state.isOpen ? <ArrowUp /> : <ArrowRight />}
          </NanoFlex>
          {this.props.main}
        </NanoFlex>
        {this.state.isOpen && (
          <NanoFlex className="treeOpenWrapper" justifyContent="flex-start" alignItems={"flex-start"}>
            {this.props.content}
          </NanoFlex>
        )}
      </StyledTreeNavigator>
    );
  }
}

export default TreeNavigator;

// --- // Documentation // --- //
/*
import TreeNavigator from '../../Nano/TreeNavigator.js';

<TreeNavigator
    main={
        <NanoFlex justifyContent='flex-start'>
            <Type.h5><b>Group Title Test</b></Type.h5>
        </NanoFlex>}
    content={
        <div>
            <Type.h5><b>Item 1</b></Type.h5>
            <Type.h5><b>Item 2</b></Type.h5>
            <Type.h5><b>Item 3</b></Type.h5>
            <Type.h5><b>Item 4</b></Type.h5>
            <Type.h5><b>Item 5</b></Type.h5>
            <Type.h5><b>Item 6</b></Type.h5>
        </div>}
/>

<NanoFlex margin={'40px'} maxWidth='300px'>
    <NanoFlex flexDirection={'column'} justifyContent='flex-start' alignItems='flex-start'>
        <TreeNavigator
            main={
                <NanoFlex justifyContent='flex-start'>
                    <Type.h5><b>Group Title</b></Type.h5>
                </NanoFlex>}
            content={
                <div>
                    <Type.h5><b>Item 1</b></Type.h5>
                    <Type.h5><b>Item 2</b></Type.h5>
                    <Type.h5><b>Item 3</b></Type.h5>
                    <Type.h5><b>Item 4</b></Type.h5>
                    <Type.h5><b>Item 5</b></Type.h5>
                    <Type.h5><b>Item 6</b></Type.h5>
                </div>}
        />

        <TreeNavigator
            main={
                <NanoFlex justifyContent='flex-start'>
                    <Type.h5><b>Group Title</b></Type.h5>
                </NanoFlex>}
            content={
                <div>
                    <Type.h5><b>Item 1</b></Type.h5>
                    <Type.h5><b>Item 2</b></Type.h5>
                    <Type.h5><b>Item 3</b></Type.h5>
                    <TreeNavigator
                        main={
                            <NanoFlex justifyContent='flex-start'>
                                <Type.h5><b>Group Title Item 4</b></Type.h5>
                            </NanoFlex>}
                        content={
                            <div>
                                <Type.h5><b>Item 1</b></Type.h5>
                                <Type.h5><b>Item 2</b></Type.h5>
                                <Type.h5><b>Item 3</b></Type.h5>
                                <Type.h5><b>Item 4</b></Type.h5>
                                <Type.h5><b>Item 5</b></Type.h5>
                                <Type.h5><b>Item 6</b></Type.h5>
                            </div>}
                    />
                    <Type.h5><b>Item 5</b></Type.h5>
                    <Type.h5><b>Item 6</b></Type.h5>
                </div>}
        />
    </NanoFlex>
</NanoFlex>
*/
