import React from "react";
import BaseComponent from "../../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../../Global/NanoFlex";
import Type from "../../../Global/Typography.js";

// SERVICES
import candidateService from "../../../../services/CandidateService";

import NoResults from "./NoResults.js";

// COMPONENTS
import UserCard from "../../../Elements/Cards/UserCard.js";
import ButtonIcon from "../../../Elements/Buttons/ButtonIcon.js";
import Loading from "../../../Elements/Loadings/Loading.js";
import Spinner from "../../../Elements/Loadings/Spinner.js";
import EmailPopUp from "../Profile/EmailPopUp.js";

// IMAGES
import Download from "../../../Global/icons/download.svg";
import Email from "../../../Global/icons/email.svg";
import Share from "../../../Global/icons/share.svg";
import { ReactComponent as ArrowUp } from "../../../Global/icons/arrowUp.svg";

const StyledResultsList = styled(NanoFlex)`
  height: calc(100vh - 200px);

  .resultsListWrapper {
    .resultsNumber {
      height: auto;
      padding: 0 24px 16px 24px;
    }

    .scrollPercentage {
      width: 100%;
    }

    .listWrapper {
      /* overflow: auto; */
      overflow-x: hidden;
      overflow-y: auto;
      padding: 0 24px 0px 24px;
      scroll-behavior: smooth;
    }

    .bottomBarWrapper {
      height: auto;
      padding: 16px 24px;
      background-color: ${(props) => props.theme.color.greyPalette.white};
      box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
      border-radius: 32px 32px 0 0;
      -webkit-animation: scale-up-ver-bottom 0.4s ease-in-out;
      animation: scale-up-ver-bottom 0.4s ease-in-out;
      .asideBottomBar {
        width: auto;
        .btnWrapper {
          width: auto;
          margin-right: 16px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    /* SCROLL TOP BTN */
    .scrollTopBtnContainer {
      position: fixed;
      /* bottom: 80px; */
      bottom: ${(props) => (props.isSelected ? "88px" : "16px")};
      right: 16px;
      height: auto;
      width: auto;
      .scrollTopBtn {
        background-color: ${(props) => props.theme.color.main.blue};
        width: 40px;
        height: 40px;
        border-radius: 100%;
        border: 1px solid ${(props) => props.theme.color.greyPalette.lightGrey};
        cursor: pointer;
        svg {
          width: 16px;
          height: auto;
        }
      }
    }
    .showScrollTop {
      animation: slide-in-blurred-top 1.6s cubic-bezier(0.23, 1, 0.32, 1);
      display: flex;
    }
    .hideScrollTop {
      display: none;
      transition: display 1s ease-in-out;
    }

    .emailPopUpWrapper {
      height: auto;
      position: absolute;
      bottom: 0;
      z-index: 1;
    }

    .inactive {
      opacity: 0.7;
      pointer-events: none;
    }

    /* ANIMATION */
    @-webkit-keyframes scale-up-ver-bottom {
      0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
      }
      100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
      }
    }
    @keyframes scale-up-ver-bottom {
      0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
      }
      100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
      }
    }
    /* END ANIMATION */
  }
`;

class ResultsList extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
    this.isGettingData = false;

    this.listWrapper = React.createRef();
    this.requestId = 0;
    this.canShare = navigator.share ? true : false;
  }

  getDefaultState = () => {
    return {
      loading: false,
      doingStuff: false,
      filter: this.props.filter,
      options: this.getDefaultOptions(),
      hasAllData: false,
      data: [],
      count: 0,
      showScrollTop: false,
    };
  };

  getDefaultOptions = () => {
    return {
      page: 0,
      size: 20,
      selectedIds: [],
    };
  };

  componentDidMount() {
    super.componentDidMount();

    this.getData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.filter !== prevState.filter) {
      let defaultState = this.getDefaultState();
      defaultState.filter = this.state.filter;
      this.setState(defaultState, () => {
        this.getData();
      });
    }

    if (this.state.count !== prevState.count && this.props.onCountChange) {
      this.props.onCountChange(this.state.count);
    }

    if (JSON.stringify(this.props.filter) !== JSON.stringify(prevProps.filter)) {
      this.setState({ filter: this.props.filter });
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  getData = () => {
    if (!this.state.hasAllData) {
      this.isGettingData = true;
      this.requestId++;
      let currentRequestId = this.requestId;
      this.setState({ loading: true }, () => {
        let options = { ...this.state.options };
        options.page++;

        candidateService
          .getCandidatesOverviewMobile({ ...this.state.filter, ...options })
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              if (response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
            }
          })
          .then((data) => {
            if (data && this.requestId === currentRequestId) {
              let newData = [...this.state.data, ...data.candidates];
              this.setState(
                {
                  loading: false,
                  data: newData,
                  count: data.count,
                  hasAllData: newData.length === data.count,
                  options: options,
                },
                () => {
                  this.isGettingData = false;
                }
              );
            }
          });
      });
    }
  };

  onListScroll = () => {
    var elm = this.listWrapper.current;
    var percentage = (elm.scrollTop / (elm.scrollHeight - elm.offsetHeight)) * 100;

    if (elm.scrollTop > 250) {
      if (!this.state.showScrollTop) {
        this.setState({ showScrollTop: true });
      }
    } else {
      if (this.state.showScrollTop) {
        this.setState({ showScrollTop: false });
      }
    }

    if (percentage > 75) {
      this.getData();
    }
  };

  scrollTop = () => {
    this.listWrapper.current.scrollTo({ top: 0, behavior: "auto" });
  };

  onSelectedChange = (id) => {
    let options = { ...this.state.options };
    let selectedId = options.selectedIds.find((v) => v === id);
    if (selectedId) {
      options.selectedIds = options.selectedIds.filter((v) => v !== id);
    } else {
      options.selectedIds.push(id);
    }
    this.setState({ options: options });
  };

  onDownloadCvs = () => {
    this.setState({ doingStuff: true }, () => {
      for (const candidateId of this.state.options.selectedIds) {
        candidateService.downloadCandidateReport(candidateId).then(() => {
          if (candidateId === this.state.options.selectedIds.slice(-1).pop()) {
            this.setState({ doingStuff: false });
          }
        });
      }
    });
  };

  onShare = () => {
    if (this.canShare === true) {
      this.setState({ doingStuff: true }, () => {
        let candidatesIds = this.state.options.selectedIds.join(",");

        //candidateService.shareCandidateToken(this.state.options.selectedIds).then((response) => {
        //  if (response.ok) {
        //    return response.json();
        //  }
        //});

        // candidateService
        //   .getCandidatesReportFileUrl(candidatesIds)
        //   .then((response) => {
        //     if (response.ok) {
        //       return response.json();
        //     }
        //   })
        //   .then((fileUrl) => {
        //     if (fileUrl) {
        //       let data = {
        //         url: fileUrl,
        //         text: "Test",
        //         title: "Share",
        //       };

        //       navigator.share(data).catch((error) => {
        //         console.log(error);
        //       });
        //     }
        //   });

        // candidateService.generateCandidatesReport(candidatesIds).then(() => {
        //   this.setState({ doingStuff: false });
        // });
      });
    }
  };

  render() {
    let showLoading = (this.state.loading && this.state.data?.length == 0) || this.state.doingStuff;
    let showSpinner = this.state.loading && this.state.data?.length > 0;

    return (
      <StyledResultsList className="resultsListContainer" flexDirection="column" justifyContent="flex-start" isSelected={this.state.options.selectedIds?.length > 0}>
        {showLoading && <Loading />}
        {!showLoading && !(this.state.count > 0) && <NoResults />}
        {!showLoading && this.state.count > 0 && (
          <NanoFlex className="resultsListWrapper" flexDirection="column" justifyContent="flex-start">
            <NanoFlex className="mainWrapper" flexDirection="column">
              <NanoFlex className="resultsNumber">
                <Type.p>
                  <b>{this.state.count}</b> Matchs
                </Type.p>
              </NanoFlex>
              <NanoFlex ref={this.listWrapper} className="listWrapper" flexDirection="column" justifyContent="flex-start" onScroll={this.onListScroll}>
                {this.state.data?.length > 0 &&
                  this.state.data.map((item) => {
                    return (
                      <UserCard
                        key={item.id}
                        item={item}
                        selected={this.state.options.selectedIds.includes(item.id)}
                        onClick={() => {
                          this.props.openProfile(item.id, item.matchPercentage);
                        }}
                        onSelect={() => {
                          this.onSelectedChange(item.id);
                        }}
                      />
                    );
                  })}
                {showSpinner && <Spinner />}
              </NanoFlex>
              {this.state.options.selectedIds?.length > 0 && (
                <NanoFlex className="bottomBarWrapper" justifyContent="space-between">
                  <NanoFlex className="asideBottomBar">
                    <NanoFlex
                      className={`btnWrapper ${this.state.options.selectedIds?.length > 1 ? "inactive" : ""}`}
                      onClick={() => {
                        this.setState({ openEmail: true });
                      }}
                    >
                      <ButtonIcon icon={Email} />
                    </NanoFlex>
                    <NanoFlex
                      className="btnWrapper"
                      onClick={() => {
                        this.onDownloadCvs();
                      }}
                    >
                      <ButtonIcon icon={Download} />
                    </NanoFlex>
                  </NanoFlex>
                  {/*{this.canShare && (*/}
                  {/*  <NanoFlex className="asideBottomBar">*/}
                  {/*    <NanoFlex*/}
                  {/*      className="btnWrapper"*/}
                  {/*      onClick={() => {*/}
                  {/*        this.onShare();*/}
                  {/*      }}*/}
                  {/*    >*/}
                  {/*      <ButtonIcon blue icon={Share} />*/}
                  {/*    </NanoFlex>*/}
                  {/*  </NanoFlex>*/}
                  {/*)}*/}
                </NanoFlex>
              )}
              <NanoFlex className={`scrollTopBtnContainer ${this.state.showScrollTop ? "showScrollTop" : "hideScrollTop"}`}>
                <NanoFlex className="scrollTopBtn" onClick={this.scrollTop}>
                  <ArrowUp />
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
            {/* EMAIL POPUP */}
            {!showLoading && this.state.count > 0 && this.state.options.selectedIds?.length > 0 && this.state.openEmail && (
              <NanoFlex className="emailPopUpWrapper">
                <EmailPopUp
                  closeEmail={() => {
                    this.setState({ openEmail: false });
                  }}
                  selectedIds={this.state.options.selectedIds}
                />
              </NanoFlex>
            )}
            {/* END EMAIL POPUP */}
          </NanoFlex>
        )}
      </StyledResultsList>
    );
  }
}

export default ResultsList;
