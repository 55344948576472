import React, { Component } from "react";
import { Link } from "react-router-dom";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";
import { validateEmailFormat } from "../../Global/tools.js";

//SERVICES
import AccountService from "../../../services/AccountService.js";

// COMPONENTS
import ButtonCTA from "../../Elements/Buttons/ButtonCTA.js";
import Input from "../../Elements/Forms/Input.js";

// IMAGES
import { ReactComponent as MlogLogo } from "../../Global/images/mlogLogoSignature.svg";
import { ReactComponent as PoweredBy } from "../../Global/icons/poweredBy.svg";
import { ReactComponent as Check } from "../../Global/icons/check.svg";
import { ReactComponent as Alert } from "../../Global/icons/alert.svg";
import accountService from "../../../services/AccountService.js";

const StyledForgotPassword = styled(NanoFlex)`
  background: linear-gradient(0deg, #0b679f 0%, #ffffff 100%);
  height: 100vh;
  width: 100vw;

  .logoWrapper {
    height: 20vh;
    padding-top: 5vh;
    svg {
      width: auto;
      height: 100%;
    }
  }

  .loginContainer {
    height: calc(100vh - 25vh);
    position: relative;

    .bckWrapper {
      position: fixed;
      height: 100%;
      width: 100%;

      .bckObject {
        width: 100%;
        height: 100%;
        transform: skew(0deg, -6deg);
        background-color: ${(props) => props.theme.color.main.blue};
        border-radius: 55px;
        position: absolute;
        top: 20px;
      }
      .bckObject2 {
        width: 100%;
        height: 100%;
        transform: skew(0deg, -6deg) rotate(-6deg);
        background-color: ${(props) => props.theme.color.main.blue};
        opacity: 0.4;
        border-radius: 55px;
      }
    }

    /* RECOVER PASSWORD */
    .recoverPassWrapper {
      padding: 60px 20px 20px 20px;
      position: relative;
      .form {
        width: 100%;
        a {
          text-decoration: none;
        }
      }
    }
    /* END RECOVER PASSWORD */

    /* RECOVER PASSWORD */
    .successRecoverWrapper {
      padding: 60px 40px 20px 40px;
      position: relative;
      .formWrapper {
        height: 100%;
        a {
          text-decoration: none;
        }
      }
    }
    /* END RECOVER PASSWORD */

    /* RECOVER PASSWORD */
    .errorRecoverWrapper {
      padding: 60px 40px 20px 40px;
      position: relative;
      .formWrapper {
        height: 100%;
        a {
          text-decoration: none;
        }
      }
    }
    /* END RECOVER PASSWORD */

    .formWrapper {
      padding: 20px 5vw;
      position: relative;
      z-index: 2;
      height: auto;
      .headerForm {
        height: auto;
        margin-bottom: 32px;
        h2 {
          b {
            color: ${(props) => props.theme.color.greyPalette.white};
          }
        }
      }

      .messageWrapper {
        .iconMessageWrapper {
          margin-bottom: 24px;
          svg {
            height: 80px;
            width: auto;
            path {
              fill: ${(props) => props.theme.color.greyPalette.white};
            }
          }
        }
        .messageContainer {
          text-align: center;
          h5 {
            margin-bottom: 16px;
            color: ${(props) => props.theme.color.greyPalette.white};
            text-transform: uppercase;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      .submitContainer {
        margin-top: 24px;
      }

      .passwordForgotContainer {
        margin-top: 24px;
        p {
          text-align: center;
          cursor: pointer;
          color: ${(props) => props.theme.color.greyPalette.white};
        }
      }

      .backToLoginBtn {
        margin-top: 32px;
        h4 {
          text-transform: uppercase;
          text-align: center;
          cursor: pointer;
          color: ${(props) => props.theme.color.greyPalette.white};
        }
      }
    }

    .poweredWrapper {
      position: relative;
      z-index: 2;
      height: auto;
      padding: 20px;
      .poweredBy {
        width: 30vw;
        svg {
          width: auto;
          height: auto;
        }
      }
    }
  }

  .inputContainer {
    .title {
      color: ${(props) => props.theme.color.greyPalette.white};
    }
  }
`;

export class ForgotPassword extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      error: false,
      status: null,
    };
  }

  componentDidMount() {
    super.componentDidMount();

    if (accountService.getToken() !== null) {
      /*Remove User From LocalStorage */
      accountService.removeToken();
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  sendForgotPassword = (event) => {
    // Prevent page refresh
    event.preventDefault();

    if (validateEmailFormat(this.state.email)) {
      accountService
        .forgotPassword(this.state.email)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            this.setState({ status: false });
          }
        })
        .then((data) => {
          if (data) {
            this.setState({ status: true });
          } else {
            this.setState({ error: true });
          }
        });
    } else {
      this.setState({ error: true });
    }
  };

  render() {
    return (
      <StyledForgotPassword flexDirection="column" justifyContent="space-between">
        <NanoFlex className="logoWrapper">
          <MlogLogo />
        </NanoFlex>
        <NanoFlex className="loginContainer" flexDirection="column" justifyContent="space-between">
          <NanoFlex className="bckWrapper">
            <NanoFlex className="bckObject"></NanoFlex>
            <NanoFlex className="bckObject2"></NanoFlex>
          </NanoFlex>

          {/* RECOVER PASSWORD */}
          {this.state.status === null && (
            <NanoFlex className="recoverPassWrapper" flexDirection="column" justifyContent="space-between">
              <form className="form" onSubmit={this.sendForgotPassword}>
                <NanoFlex className="formWrapper" flexDirection="column">
                  <NanoFlex className="headerForm">
                    <Type.h2>
                      <b>Recuperar Password</b>
                    </Type.h2>
                  </NanoFlex>
                  <NanoFlex className="formContainer" flexDirection="column">
                    <Input title="Email" placeholder="Inserir email..." error={this.state.error} onChangeAction={(value) => this.setState({ email: value })} />
                  </NanoFlex>
                  <NanoFlex className="submitContainer">
                    <ButtonCTA darkBlue label="Enviar" />
                  </NanoFlex>
                  <NanoFlex className="backToLoginBtn">
                    <Link to="/login">
                      <Type.h4>
                        <b>Voltar ao Login</b>
                      </Type.h4>
                    </Link>
                  </NanoFlex>
                </NanoFlex>
              </form>
            </NanoFlex>
          )}
          {/* END RECOVER PASSWORD */}

          {/* RECOVER SUCCESS */}
          {this.state.status === true && (
            <NanoFlex className="successRecoverWrapper" flexDirection="column" justifyContent="space-between">
              <NanoFlex className="formWrapper" flexDirection="column">
                <NanoFlex className="messageWrapper" flexDirection="column">
                  <NanoFlex className="iconMessageWrapper">
                    <Check />
                  </NanoFlex>
                  <NanoFlex className="messageContainer" flexDirection="column">
                    <Type.h5>O seu pedido de recuperação da Password foi enviado com sucesso!</Type.h5>
                    <Type.h5>Verifique o seu email.</Type.h5>
                  </NanoFlex>
                </NanoFlex>
                <NanoFlex className="backToLoginBtn">
                  <Link to="/login">
                    <Type.h4>
                      <b>Voltar ao Login</b>
                    </Type.h4>
                  </Link>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
          )}
          {/* END RECOVER SUCCESS */}

          {/* RECOVER ERROR */}
          {this.state.status === false && (
            <NanoFlex className="errorRecoverWrapper" flexDirection="column" justifyContent="space-between">
              <NanoFlex className="formWrapper" flexDirection="column">
                <NanoFlex className="messageWrapper" flexDirection="column">
                  <NanoFlex className="iconMessageWrapper">
                    <Alert />
                  </NanoFlex>
                  <NanoFlex className="messageContainer" flexDirection="column">
                    <Type.h5>Ocorreu um erro no envio do seu pedido de recuperação de Password.</Type.h5>
                  </NanoFlex>
                </NanoFlex>
                <NanoFlex className="submitContainer">
                  <ButtonCTA darkBlue label="Tentar Novamente" />
                </NanoFlex>
                <NanoFlex className="backToLoginBtn">
                  <Link to="/login">
                    <Type.h4>
                      <b>Voltar ao Login</b>
                    </Type.h4>
                  </Link>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
          )}
          {/* END RECOVER ERROR */}

          <NanoFlex className="poweredWrapper" justifyContent="flex-end" alignItems="flex-end">
            <NanoFlex className="poweredBy" justifyContent="flex-end" alignItems="flex-end">
              <a href="https://www.medialog.pt" target="_blank" rel="noopener noreferrer">
                <PoweredBy />
              </a>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledForgotPassword>
    );
  }
}
